import {Repository} from "@lib/common/repository/Repository"
import {File} from "@generated/dev/api/model/file/File"
import {AddisonLohnabrechnungExport} from "@generated/de/lohn24/model/lohnabrechnung/export/AddisonLohnabrechnungExport"

export class LohnabrechnungExportRepository extends Repository<AddisonLohnabrechnungExport> {

    constructor() {
        super(AddisonLohnabrechnungExport, "lohnabrechnungexport")
    }

    files = this.buildRelation(File, "files")
}