import {MenuItem} from "@lib/common/menu/MenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"

export const MitarbeiterMenuItem = new MenuItem({
    path: "/",
    label: "Mitarbeiter",
    name: "mitarbeiter",
    rights: [RightAccess.readable(RightIdentifier.INTRANET)],
    view: () => import(`@intranet/view/mitarbeiter/Mitarbeiter.vue`),
})