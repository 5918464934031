import {MenuItem} from "@lib/common/menu/MenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"

export const NewsVerwaltenMenuItem = new MenuItem({
    label: `Verwalten`,
    name: "verwalten",
    props: {ungelesen: false, liste: false},
    rights: [RightAccess.full(RightIdentifier.NEWS)],
    view: () => import("@intranet/view/news/verwaltung/NewsVerwaltung.vue"),
})