import {Repository} from "@lib/common/repository/Repository"
import {File} from "@generated/dev/api/model/file/File"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {AddisonExportListItem} from "@generated/de/lohn24/endpoint/AddisonExportList/AddisonExportListItem"
import {Serializable} from "@lib/common/serializable/Serializable"
import {from} from "@lib/common/Functions"
import {
    AddisonFileAssociationListItem,
} from "@generated/de/lohn24/endpoint/AddisonFileAssociationList/AddisonFileAssociationListItem"
import {AddisonMandantExport} from "@generated/de/lohn24/model/mandant/export/AddisonMandantExport"

export class AddisonExportListDistincts extends Serializable<AddisonExportListDistincts> {}

export class MandantExportRepository extends Repository<AddisonMandantExport> {

    constructor() {
        super(AddisonMandantExport, "mandantexport")
    }

    files = this.buildRelation(File, "files")
    mandant = this.buildRelation(Mandant, "mandant")

    async addisonExportList<K>(params: K): Promise<PartitionedResultSet<AddisonExportListItem, AddisonExportListDistincts>> {
        const result = await this.api.get<PartitionedResultSet<AddisonExportListItem, AddisonExportListDistincts>>(this.route("exportlist"), params)
        return new PartitionedResultSet(AddisonExportListItem, AddisonExportListDistincts, result)
    }

    async associationFile(mandant: number | null, timeout: boolean = false, failure: boolean = false): Promise<AddisonFileAssociationListItem[]> {
        const result = await this.api.get<AddisonFileAssociationListItem[]>(this.route("filelist"), {
            mandant: mandant,
            timeout: timeout,
            failure: failure,
        })
        return result.map(it => from(AddisonFileAssociationListItem, it))
    }
}

