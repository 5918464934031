import Api from "@lib/common/axios/Api"
import RepositoryConfig, {repositoryConfig} from "@lib/common/repository/RepositoryConfig"

export class CommandRepository {
    api: Api

    constructor(config: RepositoryConfig = repositoryConfig) {
        this.api = Api.Instance(config.baseURL)
    }

    async activateInactiveMandantCommand(kennung: string): Promise<void> {
        await this.api.post<void>("/mandant/activate", {kennung})
    }

    async resetMandantExport(kennung: string): Promise<void> {
        await this.api.post<void>("/mandantexport/reset", {kennung})
    }
}