<script lang="ts" setup>

import {computed, shallowRef, watch} from "vue"
import {MandantArtikelListController} from "@intranet/view/mandant/artikel/MandantArtikelListController"
import {Id} from "@lib/common/model/Id"
import {MandantArtikelViewModel} from "@intranet/view/mandant/artikel/MandantArtikelViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {Resource} from "@lib/model/Resource"
import {auth} from "@lib/common/Authentication"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import CrudColumn from "@lib/view/list/components/CrudColumn.vue"
import ListContent from "@lib/view/list/listcontent/ListContent.vue"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {RightAccess} from "@lib/model/role/RoleRight"
import {ValidateableList} from "@lib/common/ValidateableListItem"
import Validateable from "@lib/view/validateable/Validateable.vue"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import ArtikelkatalogArtikelArtikelOptions from "@intranet/view/artikel/ArtikelkatalogArtikelArtikelOptions.vue"

export interface MandantArtikelProps {
    id: Id<Mandant>
    artikelkatalog: Id<Artikelkatalog> | null
    controller: MandantArtikelListController | null
    path?: string
}

const emits = defineEmits<{
    "update:controller": [controller: MandantArtikelListController | null]
    "update:artikelkatalog": [artikelkatalog: Id<Artikelkatalog> | null]
}>()
const props = withDefaults(
    defineProps<MandantArtikelProps>(),
    {
        path: "mandantartikel",
    },
)

const mandantArtikelController = shallowRef<MandantArtikelListController | null>(null)
const selectedArtikel = computed<Id<Artikel>[]>(() => {
    return mandantArtikelController.value?.artikel.value.reduce((acc, artikel) => {
        if (artikel.id?.value && mandantArtikelController.value?.data.find(item => item.artikelId?.value === artikel.id.value))
            acc.push(artikel.id)
        return acc
    }, [] as Id<Artikel>[]) ?? []
})

const artikelMap = computed<Record<number, Artikel>>(() => {
        return mandantArtikelController.value?.artikelMap.value ?? {}
    },
)

const hasMandantArtikelReadAccess = computed(() => auth.hasAccess([RightAccess.readable(RightIdentifier.MANDANT_ARTIKEL)]))
const hasMandantArtikelFullAccess = computed(() => auth.hasAccess([RightAccess.full(RightIdentifier.MANDANT_ARTIKEL)]))

function rowClassNames({row}: { row: MandantArtikelViewModel, rowIndex: number }) {
    const classNames: string[] = []
    if (row.bearbeitungszustand.deleted) {
        classNames.push("row-deleted")
    }
    return classNames.join(" ")
}

watch([() => props.id?.value, () => props.artikelkatalog?.value], async ([id, artikelkatalog]) => {
    if (!artikelkatalog) {
        emits("update:artikelkatalog", await Resource.mandant.artikelkatalog(new Id(id)))
    } else if (id && artikelkatalog) {
        if (props.controller?.mandant.value === id && props.controller.artikelkatalog.value === artikelkatalog) {
            mandantArtikelController.value = props.controller
            if (!mandantArtikelController.value.validateableList) {
                mandantArtikelController.value.validateableList = new ValidateableList<MandantArtikelViewModel>(
                    props.path,
                    it => it.mandantArtikel,
                )
            }
        } else {
            mandantArtikelController.value = new MandantArtikelListController(new Id(id), new Id(artikelkatalog))
            mandantArtikelController.value.validateableList = new ValidateableList<MandantArtikelViewModel>(
                props.path,
                it => it.mandantArtikel,
            )
            await mandantArtikelController.value.fetch()
            emits("update:controller", mandantArtikelController.value)
        }
    } else {
        mandantArtikelController.value = null
        emits("update:controller", mandantArtikelController.value)
    }
}, {immediate: true})

function error(row: MandantArtikelViewModel, name: string): string {
    return mandantArtikelController.value?.validateableList?.error(row, name) ?? ""
}

function prop(name: string, index: number): string {
    return mandantArtikelController.value?.validateableList?.prop(name, index) ?? ""
}

function validate(row: MandantArtikelViewModel) {
    mandantArtikelController.value?.validateableList?.validate(row)
}

</script>

<template>
  <el-row class="mandant-artikel">
    <el-col>
      <h3 class="heading">Preise</h3>
    </el-col>
    <el-col>
      <list-content
              v-if="mandantArtikelController"
              :controller="mandantArtikelController"
              :paginatable="false"
              :row-class-name="rowClassNames"
              :settings="false"
              classprefix="mandant-artikel"
              element-loading-text="Daten werden geladen..."
              title="Preise"
      >
        <template #columns>
          <el-table-column :min-width="180" label="Artikel">
            <template #default="{row}: {row: MandantArtikelViewModel}">
              <validateable
                      no-margin
                      :error="error(row, MandantArtikel.props.artikel)"
              >
                <artikelkatalog-artikel-artikel-options
                        v-model="row.artikelId"
                        :artikelkatalog="artikelkatalog!"
                        :disabled="!row.bearbeitungszustand.edited"
                        :selected="selectedArtikel"
                        @change="validate(row)"
                        :immediate="true"
                />
              </validateable>
            </template>
          </el-table-column>
          <el-table-column
                  :label="MandantArtikelViewModel.label.name"
                  :min-width="140"
          >
            <template #default="{row}">
              <el-input
                      v-model="row.name"
                      :disabled="!row?.bearbeitungszustand.edited"
                      :placeholder="row.artikelName"
                      @change="validate(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
                  :label="MandantArtikelViewModel.label.vorgabeMengeRechnung"
                  :width="160"
          >
            <template #default="{row}">
              <el-input-number
                      v-model="row.vorgabeMengeRechnung"
                      :disabled="!row?.bearbeitungszustand.edited"
                      :min="0"
                      :placeholder="row.artikelVorgabeMengeRechnung"
                      :precision="2"
                      :step="0.01"
                      controls-position="right"
                      @change="validate(row)"
              />
            </template>
          </el-table-column>
          <el-table-column label="Umsatzsteuersatz %" prop="umsatzsteuersatz" />
          <el-table-column
                  :label="MandantArtikelViewModel.label.netto"
                  :min-width="100"
          >
            <template #default="{row}">
              <el-input-number
                      v-model="row.netto"
                      :disabled="!row?.bearbeitungszustand.edited"
                      :min="0"
                      :placeholder="row.artikelNetto"
                      :precision="2"
                      :step="0.01"
                      controls-position="right"
                      @change="validate(row)"
              />
            </template>
          </el-table-column>
          <el-table-column :width="258" label="Sachbearbeiterabschluss">
            <template #default="{row}">
              <el-radio-group v-model="row.sachbearbeiterabschluss"
                              :disabled="!row.bearbeitungszustand?.edited"
                              size="small"
                              @change="validate(row)"
              >
                <el-radio-button :label="null">Wie Artikel</el-radio-button>
                <el-radio-button :label="true">Anzeigen</el-radio-button>
                <el-radio-button :label="false">Ausblenden</el-radio-button>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column :width="258" label="Rechnungsabschluss">
            <template #default="{row}">
              <el-radio-group v-model="row.rechnungsabschluss" :disabled="!row.bearbeitungszustand?.edited"
                              size="small"
                              @change="validate(row)"
              >
                <el-radio-button :label="null">Wie Artikel</el-radio-button>
                <el-radio-button :label="true">Anzeigen</el-radio-button>
                <el-radio-button :label="false">Ausblenden</el-radio-button>
              </el-radio-group>
            </template>
          </el-table-column>
          <crud-column
                  v-if="hasMandantArtikelReadAccess && mandantArtikelController"
                  :controller="mandantArtikelController"
                  :createable="mandantArtikelController?.artikelAvailable.value"
                  :deletable="hasMandantArtikelFullAccess"
                  editable
          />
        </template>
      </list-content>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.mandant-artikel {
  .el-table__cell {
    .cell {
      .el-radio-button {
        display: inline-grid;
      }
    }
  }

  .el-select,
  .el-input-number {
    width: 100%;
  }

  .el-input-number__increase,
  .el-input-number__decrease {
    height: 30px;
  }

  .el-input-number.is-controls-right {
    .el-input-number__increase {
      height: 10px;
      top: 2px;
    }

    .el-input-number__decrease {
      height: 10px;
      bottom: 2px;
    }
  }

  .row-deleted {
    color: var(--el-disabled-text-color);
  }
}
</style>
