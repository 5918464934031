<template>
  <el-row class="mandant-organisation">
    <el-col>
      <el-row>
        <el-col>
          <h3>Organisation</h3>
        </el-col>
        <el-col></el-col>
      </el-row>
      <el-table
              :data="data"
              :row-class-name="rowClassName"
              size="small"
              stripe
              @row-contextmenu="(row, col, event) => openContextMenu({
                event: event,
                row: row,
                column: col,
                route: $route,
                router: $router
              })"
      >
        <el-table-column
                :label="MandantOrganisationViewModel.label.kennung"
                :prop="MandantOrganisationViewModel.properties.kennung"
                :width="80"
        />
        <el-table-column
                :label="MandantOrganisationViewModel.label.name"
                :prop="MandantOrganisationViewModel.properties.name"
        />
        <el-table-column
                :label="MandantOrganisationViewModel.label.abrechnungszeitraum"
                :prop="MandantOrganisationViewModel.properties.abrechnungszeitraum"
                :width="150"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {MandantOrganisationViewModel} from "@intranet/view/mandant/organisation/MandantOrganisationViewModel"
import {MandantOrganisationContextMenu} from "@intranet/view/mandant/organisation/MandantOrganisationContextMenu"
import {ContextMenuRowContext} from "@lib/common/contextmenu/ContextMenuBuilder"

export default defineComponent({
    props: {
        mandanten: {
            type: Array as PropType<Mandant[]>,
            required: true,
        },
    },
    setup(props) {
        const data = computed(() => props
            .mandanten
            .map(it => new MandantOrganisationViewModel(it))
            // .filter(it => it.mandant.status !== MandantStatus.INAKTIV)
            .sortBy(it => it.kennung)
        )

        function rowClassName(): string {
            return ""
        }

        function openContextMenu(context: ContextMenuRowContext<MandantOrganisationViewModel>) {
            new MandantOrganisationContextMenu(context)
        }

        return {
            data,
            rowClassName,
            openContextMenu,
            MandantOrganisationViewModel,
        }
    },
})
</script>

<style scoped>

</style>