import {computed, ComputedRef} from "vue"
import {DialogController} from "@lib/common/controller/DialogController"
import {ConfirmVerwerfenError, ValidationError} from "@lib/common/axios/AxiosError"
import {Resource} from "@lib/model/Resource"
import {Config} from "@lib/Config"
import {ElMessageBox} from "element-plus"
import {LocalMonth} from "@lib/common/LocalMonth"
import {BelegBuilderController} from "@intranet/view/rechnung/builder/BelegBuilderController"
import {BelegBuilderViewModel} from "@intranet/view/rechnung/builder/BelegBuilderViewModel"
import {Nullable} from "@lib/types"
import {BelegBuilderModus} from "@intranet/view/rechnung/builder/BelegBuilderModus"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {assertNotNull} from "@lib/common/Functions"
import {EinzelrechnungVorschauPdf} from "@intranet/view/pdf/EinzelrechnungVorschauPdf"
import {PdfMenuItem} from "@intranet/view/pdf/PdfMenuItem"
import {confirmAbschluss, mandantInkonsistent} from "@intranet/view/mandant/MandantFunctions"
import {
    confirmEinzelrechnungFestschreiben,
    confirmEinzelrechnungFreigeben,
} from "@intranet/view/rechnung/einzelrechnung/EinzelrechnungFunctions"
import {Id} from "@lib/common/model/Id"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"

export class BelegBuilderDialogController extends DialogController {

    belegBuilder: BelegBuilderController
    title: ComputedRef<string> = computed(() => {
        return `${this.kennung} // ${this.name} // ${this.belegBuilderViewModel.zeitraumMMYYYY}`
    })

    constructor(
        public name: string,
        public kennung: string,
        public belegBuilderViewModel: BelegBuilderViewModel,
        public modus: BelegBuilderModus,
    ) {
        super()
        this.belegBuilder = new BelegBuilderController(
            belegBuilderViewModel,
            modus,
        )
    }

    get submitText() {
        switch (this.modus) {
            case BelegBuilderModus.SACHBEARBEITERABSCHLUSS:
                return "Abschließen"
            case BelegBuilderModus.MANUELL:
            case BelegBuilderModus.RECHNUNGSABSCHLUSS:
                return this.belegBuilderViewModel.isSammelrechnung
                    ? "Zur Rechnung freigeben"
                    : "Festschreiben"
            default:
                return "error"
        }
    }

    override modified(): boolean {
        return this.belegBuilder.modified
    }

    static async verwerfen(viewModel: BelegBuilderViewModel) {
        await viewModel.verwerfen()
        await Config.showDataSavedSuccessfullyMessage()
    }

    async zwischenspeichern(): Promise<void> {
        try {
            await this.belegBuilder.updateDebounced()
        } catch (e) {
            if (e instanceof ConfirmVerwerfenError) {
                await this.confirmKeineRechnung(this.name, this.kennung, this.belegBuilder.model.value.zeitraum)
                await Resource.beleg.verwerfen(this.belegBuilder.model.value.builder.id)
            } else throw e
        }
    }

    confirmKeineRechnung(name: string, kennung: string, zeitraum?: Nullable<LocalMonth>) {
        return ElMessageBox.confirm(
            `Rechnung für  ${kennung} // ${name} ${zeitraum ? "im Zeitraum: " + zeitraum["datum"] : ""} verwerfen?`,
            `Rechnung verwerfen`,
            {
                cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
                confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
                cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
                confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
                type: Config.buttonLabel.TYPE_INFO,
            },
        )
    }

    confirmLoeschen(name: string, kennung: string, zeitraum?: Nullable<LocalMonth>) {
        return ElMessageBox.confirm(
            `Angefangene Rechnung für  ${kennung} // ${name} ${zeitraum ? "im Zeitraum: " + zeitraum["datum"] : ""} löschen?`,
            `Angefangene Rechnung löschen`,
            {
                cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
                confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
                cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
                confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
                type: Config.buttonLabel.TYPE_INFO,
            },
        )
    }

    async freigeben() {
        const controller = this.belegBuilder
        try {
            this.visible.value = false
            await controller.update()
            const mandantId = this.belegBuilderViewModel.builder.mandant
            const mandant = mandantId !== null ? (await Resource.mandant.find(mandantId)) : undefined
            await confirmEinzelrechnungFreigeben({
                name: this.name,
                kennung: this.kennung,
                zeitraum: assertNotNull(this.belegBuilderViewModel.zeitraum),
            }, mandant)
            await controller.model.value.freigeben()
        } catch (e) {
            this.visible.value = true
            if (e !== "cancel")
                throw e
        }
    }

    async festschreiben() {
        const controller = this.belegBuilder
        await controller.update()
        try {
            const mandantId = this.belegBuilderViewModel.builder.mandant
            const mandant = mandantId !== null ? (await Resource.mandant.find(mandantId)) : undefined
            await confirmEinzelrechnungFestschreiben({
                name: this.name,
                kennung: this.kennung,
                leistungszeitraum: this.belegBuilder.model.value.leistungszeitraum,
            }, mandant)
            controller.model.value.festschreiben()
            await Config.showDataSavedSuccessfullyMessage()
        } catch (e) {
            if (e != "cancel")
                throw e
        }
    }

    async verwerfen() {
        try {
            await this.confirmKeineRechnung(this.name, this.kennung, this.belegBuilder.model.value.zeitraum)
            await BelegBuilderDialogController.verwerfen(this.belegBuilder.model.value)
            await DialogController.closeDialogs()
        } catch (e) {
            if (e != "cancel")
                throw e
        }
    }

    async loeschen() {
        try {
            await this.confirmLoeschen(this.name, this.kennung, this.belegBuilder.model.value.zeitraum)
            if (!this.belegBuilder.model.value.bearbeitungszustand.created)
                await BelegBuilderDialogController.verwerfen(this.belegBuilder.model.value)
            await DialogController.closeDialogs()
        } catch (e) {
            if (e != "cancel")
                throw e
        }
    }

    async vorschau() {
        await this.belegBuilder.update()
        PdfMenuItem.open(EinzelrechnungVorschauPdf, {
            belegbuilder: this.belegBuilder.model.value.builder.id.value.toString(),
        })
    }

    async abschliesen(): Promise<Mandant | null> {
        const controller = this.belegBuilder
        try {
            if (await controller.save()) {
                await confirmAbschluss({
                    name: this.name,
                    kennung: this.kennung,
                    abrechnungszeitraum: assertNotNull(this.belegBuilderViewModel.zeitraum),
                })
                const builder = controller.model.value.builder
                return await Resource.mandant.abschliessen(
                    Id.required(builder.mandant),
                    assertNotNull(builder.abrechnungszeitraum),
                )
            } else {
                return null
            }
        } catch (e) {
            if (e instanceof ValidationError) {
                await mandantInkonsistent(Id.required(this.belegBuilder.model.value.builder.mandant))
                return await this.abschliesen()
            } else {
                throw e
            }
        }
    }

    async submit(): Promise<boolean> {
        switch (this.modus) {
            case BelegBuilderModus.SACHBEARBEITERABSCHLUSS: {
                try {
                    const mandant = await this.abschliesen()
                    if (mandant) {
                        await MandantSummaryDialog.show(mandant.id)
                        return true
                    } else {
                        return false
                    }
                } catch (e) {
                    if (e === "cancel")
                        return false
                    else
                        throw e
                }

            }
            case BelegBuilderModus.MANUELL:
            case BelegBuilderModus.RECHNUNGSABSCHLUSS:
                await (this.belegBuilderViewModel.isSammelrechnung
                    ? this.freigeben()
                    : this.festschreiben())
                await DialogController.closeDialogs()
                return true
            default:
                throw "Ungültiger modus"
        }
    }
}


