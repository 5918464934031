import {
    MandantBenachrichtigungsoptionViewModel,
} from "@intranet/view/mandant/benachrichtigung/MandantBenachrichtigungsoptionViewModel"
import {ListController} from "@lib/common/controller/ListController"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Resource} from "@lib/model/Resource"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {assertNotNull, from} from "@lib/common/Functions"
import {Benachrichtigungsoption} from "@generated/de/lohn24/model/benachrichtigung/Benachrichtigungsoption"

export class MandantBenachrichtigungsoptionListController extends ListController<MandantBenachrichtigungsoptionViewModel> {

    private benachrichtigungsoptionNotInContacts: Benachrichtigungsoption[] = []

    constructor(private mandant: Mandant, private organisationsKontakte: Kontakt[], private kooperationspartnerKontakte: Kontakt[] = []) {
        super()
    }

    private get merged() {
        return [...this.organisationsKontakte, ...this.kooperationspartnerKontakte]
    }

    static filterKooperationsKontakte(controller: MandantBenachrichtigungsoptionListController) {
        return controller.data.filter(it => it.isKooperationspartner)
    }

    static filterOrganisationsKontakte(controller: MandantBenachrichtigungsoptionListController) {
        return controller.data.filter(it => !it.isKooperationspartner)
    }

    override async create() {
        throw Error("Es kann je Kontakt nur eine Benachrichtigung geben")
    }

    containsAll(kontakte: Kontakt[]): boolean {

        if (this.merged.length !== kontakte.length) {
            return false
        }
        for (const kontakt of kontakte) {
            if (!this.merged.find(it => it.id?.value === kontakt.id?.value))
                return false
        }
        return true
    }

    override async update(kontakte: Kontakt[]): Promise<MandantBenachrichtigungsoptionViewModel[]> {
        return super.update(kontakte)
    }

    protected async fetchData(): Promise<MandantBenachrichtigungsoptionViewModel[]> {
        const result = await Resource.mandant.benachrichtigungsoption.all(this.mandant.id)
        const organisationViewModel = this.organisationsKontakte.map(kontakt => {
            const benachrichtigungsoption = result.find(it => it.kontakt.value === kontakt.id?.value)
            const vm = new MandantBenachrichtigungsoptionViewModel(
                benachrichtigungsoption ?? from(Benachrichtigungsoption, {kontakt: kontakt.id.value}),
                kontakt,
            )
            if (!benachrichtigungsoption) {
                vm.bearbeitungszustand.flagCreated()
            }
            return vm
        })
        const koopViewModel = this.kooperationspartnerKontakte.map(kontakt => {
            const benachrichtigungsoption = result.find(it => it.kontakt.value === kontakt.id?.value)
            const vm = new MandantBenachrichtigungsoptionViewModel(
                benachrichtigungsoption ?? from(Benachrichtigungsoption, {kontakt: kontakt.id.value}),
                kontakt,
                true,
            )
            if (!benachrichtigungsoption) {
                vm.bearbeitungszustand.flagCreated()
            }
            return vm
        })
        this.benachrichtigungsoptionNotInContacts = result.filter(it =>
            !this.merged.any(kontakt => kontakt.id?.value === it.kontakt.value),
        )
        return [...organisationViewModel, ...koopViewModel]
    }

    protected async updateData(data: MandantBenachrichtigungsoptionViewModel[], kontakte: Kontakt[]) {
        const kontaktIdCache = kontakte.associateBy(it => it.id.value)
        const toCreate = data.filter(it => {
            return it.changed && it.bearbeitungszustand.created
        })
        const toDelete = data.filter(it => {
            return it.bearbeitungszustand.deleted || (it.allFalse && !it.bearbeitungszustand.created)
        })
        const toUpdate = data.filter(it => {
            return it.changed && !(it.bearbeitungszustand.created || it.bearbeitungszustand.deleted || it.allFalse)
        })
        const rtn: Benachrichtigungsoption[] = []

        for (const deleteable of toDelete) {
            if (kontaktIdCache[deleteable.benachrichtigungsoption.kontakt.value])
                await Resource.mandant.benachrichtigungsoption.delete(this.mandant.id, deleteable.benachrichtigungsoption)
        }

        for (const benachrichtigungsoptionNotInContact of this.benachrichtigungsoptionNotInContacts) {
            await Resource.mandant.benachrichtigungsoption.delete(this.mandant.id, benachrichtigungsoptionNotInContact)
        }

        for (const create of toCreate) {
            const benachrichtigungsoption = create.benachrichtigungsoption
            if (kontaktIdCache[benachrichtigungsoption.kontakt.value])
                rtn.push(await Resource.mandant.benachrichtigungsoption.create(this.mandant.id, benachrichtigungsoption))
        }

        for (const update of toUpdate) {
            if (kontaktIdCache[update.benachrichtigungsoption.kontakt.value])
                rtn.push(await Resource.mandant.benachrichtigungsoption.update(this.mandant.id, update.benachrichtigungsoption))
        }

        return rtn.map(it => {
            return new MandantBenachrichtigungsoptionViewModel(
                it,
                assertNotNull(this.merged.find(kontakt => kontakt.id?.value === it.kontakt.value)),
                !!this.kooperationspartnerKontakte.find(kontakt => kontakt.id?.value === it.kontakt.value),
            )
        })
    }
}