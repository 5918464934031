import { IRuntimeTypeInformation, Id, Serializable } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { AddisonMandantExport } from "@generated/de/lohn24/model/mandant/export/AddisonMandantExport"

export interface IAddisonExportListItem {
	export: string
	mandant: string
	exporte: number
	failedAssociations: number
	pendingAssociations: number
	pendingExporte: number
	kennung: string
}

export class AddisonExportListItem extends Serializable<AddisonExportListItem> {
	public export!: Id<AddisonMandantExport>
	public mandant!: Id<Mandant>
	public exporte!: number
	public failedAssociations!: number
	public pendingAssociations!: number
	public pendingExporte!: number
	public kennung!: string

	static _types: Record<keyof IAddisonExportListItem, IRuntimeTypeInformation> = {
		export: { type: Id },
		mandant: { type: Id },
		exporte: { type: Number },
		failedAssociations: { type: Number },
		pendingAssociations: { type: Number },
		pendingExporte: { type: Number },
		kennung: { type: String },
	}

	static props: Record<keyof IAddisonExportListItem, string> = {
		export: "export",
		mandant: "mandant",
		exporte: "exporte",
		failedAssociations: "failedAssociations",
		pendingAssociations: "pendingAssociations",
		pendingExporte: "pendingExporte",
		kennung: "kennung",
	}

	constructor(_json: IAddisonExportListItem) {
		super(_json)
	}
}
