<script lang="ts" setup>
import {computed} from "vue"
import {MandantViewModel} from "@intranet/view/mandant/MandantViewModel"
import {auth} from "@lib/common/Authentication"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import {ElMessage, ElMessageBox} from "element-plus"
import {Config} from "@lib/Config"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {mandantInkonsistent} from "@intranet/view/mandant/MandantFunctions"
import {Resource} from "@lib/model/Resource"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {FontSize} from "@lib/common/Enums"
import IconWartend from "@lib/view/icons/IconWartend.vue"
import IconWeiter from "@lib/view/icons/IconWeiter.vue"
import IconEntsperrt from "@lib/view/icons/IconEntsperrt.vue"
import IconInaktiv from "@lib/view/icons/IconInaktiv.vue"
import IconGesperrt from "@lib/view/icons/IconGesperrt.vue"

const emits = defineEmits<{
    update: [mandant: Mandant]
    error: [error: Error]
    cancel: [reason: any]
}>()
const props = withDefaults(
    defineProps<{
        mandant: Mandant
        btnClass?: string | object
        size?: FontSize
    }>(),
    {
        size: FontSize.small,
        btnClass: "",
    },
)

const viewModel = computed(() => new MandantViewModel(props.mandant))
const hasAccess = auth.hasAccess([RightAccess.editable(RightIdentifier.RECHNUNGSWESEN)])

async function confirmUnlock(mandant: MandantViewModel): Promise<void> {
    try {
        await ElMessageBox.confirm(
            `Begründung für bisherigen Status: ${mandant.gesperrtNotiz}`,
            "Mandant entsperren?",
            {
                confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
                cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
                confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
                cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
            },
        )
        await setUnlocked(mandant)
    } catch (e: any) {
        if (e === "cancel") {
            emits("cancel", e)
        } else if (e instanceof ValidationError) {
            const konsistenterMandant = await mandantInkonsistent(mandant.mandant.id)
            if (konsistenterMandant)
                return setUnlocked(new MandantViewModel(konsistenterMandant))
        } else {
            throw e
        }
    }
}

async function confirmInaktiv(mandant: MandantViewModel): Promise<void> {
    try {
        await ElMessageBox.confirm("Status auf Inaktiv setzen?", "Status auf 'inaktiv' setzen", {
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
        })
        const result = await Resource.mandant.setzeInaktiv(mandant.mandant.id)
        Config.showDataSavedSuccessfullyMessage()
        emits("update", result)
    } catch (e: any) {
        if (e === "cancel") {
            emits("cancel", e)
        } else {
            throw e
        }
    }
}

async function confirmReaktiviereInaktivenMandanten(mandant: MandantViewModel): Promise<void> {
    try {
        await ElMessageBox.confirm("Status auf Aktiv setzen?", "Status auf 'aktiv' setzen", {
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
        })
        const result = await Resource.mandant.aktiviereInaktivenMandanten(mandant.mandant.id)
        Config.showDataSavedSuccessfullyMessage()
        mandant.status = result.status
        emits("update", result)
    } catch (e: any) {
        if (e === "cancel") {
            emits("cancel", e)
        } else if (e instanceof ValidationError) {
            e.response!.data.forEach(data => {
                ElMessage({
                    message: `Validierung Fehlgeschlagen: ${data.message}`,
                    type: "error",
                })
            })
        } else {
            throw e
        }
    }
}

async function setAktiv(mandant: MandantViewModel): Promise<void> {
    try {
        const clone = new MandantViewModel(mandant.mandant)
        clone.statusNotiz = ""
        clone.status = MandantStatus.AKTIV
        const result = await Resource.mandant.update(clone.mandant)
        Config.showDataSavedSuccessfullyMessage()
        emits("update", result)
    } catch (e: any) {
        if (e instanceof ValidationError) {
            const konsistenterMandant = await mandantInkonsistent(mandant.mandant.id)
            if (konsistenterMandant)
                return setAktiv(new MandantViewModel(konsistenterMandant))
        }
        throw e
    }
}

async function setUnlocked(mandant: MandantViewModel): Promise<void> {
    const clone = new MandantViewModel(mandant.mandant)
    clone.gesperrtNotiz = ""
    clone.gesperrt = false
    const result = await Resource.mandant.update(clone.mandant)
    Config.showDataSavedSuccessfullyMessage()
    emits("update", result)
}

async function confirmWithNote(mandant: MandantViewModel) {
    try {
        const {value} = await ElMessageBox.prompt("Grund für die Statusänderung:", "Status auf 'wartend' setzen", {
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
        })
        const clone = new MandantViewModel(mandant.mandant)
        clone.statusNotiz = value
        clone.status = MandantStatus.WARTEN
        const result = await Resource.mandant.update(clone.mandant)
        Config.showDataSavedSuccessfullyMessage()
        emits("update", result)
    } catch (e) {
        if (e === "cancel") {
            emits("cancel", e)
        } else if (e instanceof ValidationError) {
            const konsistenterMandant = await mandantInkonsistent(mandant.mandant.id)
            if (konsistenterMandant)
                await confirmWithNote(new MandantViewModel(konsistenterMandant))
        } else {
            throw e
        }
    }
}

async function confirmAktiv(mandant: MandantViewModel) {
    try {
        await ElMessageBox.confirm(
            `Begründung für bisherigen Status: ${mandant.statusNotiz}`,
            "Status auf 'abrechenbar' setzen",
            {
                cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
                confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
                confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
                cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
            },
        )
        const clone = new MandantViewModel(mandant.mandant)
        clone.statusNotiz = ""
        clone.status = MandantStatus.AKTIV
        const result = await Resource.mandant.update(clone.mandant)
        Config.showDataSavedSuccessfullyMessage()
        emits("update", result)
    } catch (e) {
        if (e === "cancel") {
            emits("cancel", e)
        } else if (e instanceof ValidationError) {
            const konsistenterMandant = await mandantInkonsistent(mandant.mandant.id)
            if (konsistenterMandant)
                return confirmAktiv(new MandantViewModel(konsistenterMandant))
        } else {
            throw e
        }
    }
}

async function confirmLock(mandant: MandantViewModel) {
    try {
        const {value} = await ElMessageBox.prompt("Grund für die Statusänderung:", "Status auf 'gesperrt' setzen", {
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
        })
        const clone = new MandantViewModel(mandant.mandant)
        clone.gesperrtNotiz = value
        clone.gesperrt = true
        const result = await Resource.mandant.update(clone.mandant)
        emits("update", result)
    } catch (e) {
        if (e === "cancel") {
            emits("cancel", e)
        } else if (e instanceof ValidationError) {
            const konsistenterMandant = await mandantInkonsistent(mandant.mandant.id)
            if (konsistenterMandant)
                return confirmLock(new MandantViewModel(konsistenterMandant))
        } else {
            throw e
        }
    }
}
</script>

<template>
  <el-tooltip v-if="hasAccess && viewModel.status === MandantStatus.INAKTIV"
              :show-after="1000"
              placement="top"
  >
    <template #content>
      <span>Den Mandanten {{ viewModel.name }} reaktivieren</span>
    </template>
    <el-button
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmReaktiviereInaktivenMandanten(viewModel)"
    >
      REAKTIVIEREN
    </el-button>
  </el-tooltip>
  <el-tooltip v-if="hasAccess && mandant.kuendigungsdatum && viewModel.status !== MandantStatus.INAKTIV"
              :show-after="1000"
              placement="top"
  >
    <template #content>
      <span>Den Mandanten {{ viewModel.name }} inaktiv setzen</span>
    </template>
    <el-button
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmInaktiv(viewModel)"
    >
      <icon-inaktiv :size="size" />
    </el-button>
  </el-tooltip>
  <el-tooltip v-if="hasAccess"
              :show-after="1000"
              placement="top"
  >
    <template #content>
      <span v-if="!viewModel.gesperrt">Mandant {{ viewModel.kennung }} sperren</span>
      <span v-else>Den Mandanten {{ viewModel.name }} entsperren</span>
    </template>
    <el-button
            v-if="viewModel.gesperrt"
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmUnlock(viewModel)"
    >
      <icon-entsperrt :size="size" />
    </el-button>
    <el-button v-else :class="btnClass" text v-bind="$attrs" @click.stop="confirmLock(viewModel)">
      <icon-gesperrt :size="size" />
    </el-button>
  </el-tooltip>
  <el-tooltip v-if="!viewModel.gesperrt && viewModel.status !== MandantStatus.INAKTIV"
              :show-after="1000"
              placement="top"
  >
    <template #content>
      <span v-if="viewModel.status === MandantStatus.WARTEN"
      >Mandant {{ viewModel.kennung }} auf abrechenbar setzen</span
      >
      <span v-else>Mandant {{ viewModel.kennung }} auf wartend setzen</span>
    </template>
    <el-button
            v-if="viewModel.status === MandantStatus.WARTEN"
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmAktiv(viewModel)"
    >
      <icon-weiter :size="size" />
    </el-button>
    <el-button v-else :class="btnClass" text v-bind="$attrs" @click.stop="confirmWithNote(viewModel)">
      <icon-wartend :size="size" />
    </el-button>
  </el-tooltip>
</template>

