import {Repository} from "@lib/common/repository/Repository"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {
    RepositoryDispatchMap,
    RepositoryEventMap,
    RepostioryEvents,
} from "@lib/common/repository/RepositoryEventTarget"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {
    MandantTemporaererSachbearbeiterCommandBody,
} from "@lib/model/mandant/MandantTemporaererSachbearbeiterCommandBody"
import {Debitor, IDebitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {RecordOf} from "@lib/types"
import {Id} from "@lib/common/model/Id"
import {LocalMonth} from "@lib/common/LocalMonth"
import {MandantListDistinct} from "@lib/model/mandant/MandantListDistinct"
import {MandantBuilder} from "@generated/de/lohn24/model/mandant/MandantBuilder"
import {Benachrichtigungsoption} from "@generated/de/lohn24/model/benachrichtigung/Benachrichtigungsoption"
import {ResourceCache} from "@lib/model/Resource"
import {DebitorBuilder} from "@generated/de/lohn24/model/debitor/DebitorBuilder"
import {AutomatischeRechnung} from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import {MandantListElement} from "@generated/de/lohn24/model/mandant/MandantListElement"
import {Ticketgruppe} from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import {Mitarbeiter} from "@generated/de/lohn24/model/mitarbeiter/Mitarbeiter"
import {from} from "@lib/common/Functions"
import {IMandant, Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {ISearchResult, SearchResult, SearchResultTopic} from "@lib/view/search/SearchResult"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {auth} from "@lib/common/Authentication"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import {RightAccess} from "@lib/model/role/RoleRight"
import {SftpConfiguration} from "@generated/de/lohn24/model/configuration/SftpConfiguration"
import {AddisonLohnabrechnungExport} from "@generated/de/lohn24/model/lohnabrechnung/export/AddisonLohnabrechnungExport"

export type MandantRepositoryEvents = RepostioryEvents | "abschluss" | "temporaerersachbearbeiter"

export type MandantRepositoryDispatchMap = RepositoryDispatchMap<Mandant> & {
    "abschluss": Mandant,
    "temporaerersachbearbeiter": void
}

export type MandantRepositoryEventMap = RepositoryEventMap<Mandant> & {
    "abschluss": (ev: Mandant) => void,
    "temporaerersachbearbeiter": (ev: undefined) => void
}

export type MandantAnschriftentraegerQuery = { etikett?: "true", kooperationspartner?: "true" }

export class MandantRepository extends Repository<Mandant> {
    debitor = this.buildRelation(Debitor, "debitor")
    mandantartikel = this.buildHasManyWithRelation(MandantArtikel, ArtikelkatalogArtikel, "artikel")
    benachrichtigungsoption = this.buildRelation(Benachrichtigungsoption, "benachrichtigungsoption")
    anschrift = this.buildRelation(Anschrift, "anschrift")
    koopertionspartner = this.buildRelation(Kooperationspartner, "kooperationspartner")
    builder = this.buildBuilder(MandantBuilder)
    sftpConfiguration = this.buildConfiguration(SftpConfiguration, "sftp")

    exporte = this.buildRelation(AddisonLohnabrechnungExport, "exporte")

    constructor() {
        super(Mandant, "mandant")
        this.builder.addEventListener("finalize", () => {
            ResourceCache.organisation.invalidate()
            ResourceCache.mandant.invalidate()
        })
    }

    override async all<K>(params?: K): Promise<Mandant[]> {
        if (auth.hasAccess([RightAccess.full(RightIdentifier.INTERN)]))
            return super.all(params)

        return (await super.all(params)).filter(it => !it.intern)
    }

    override async allUnlimited<K>(params?: K | undefined): Promise<Mandant[]> {
        if (auth.hasAccess([RightAccess.full(RightIdentifier.INTERN)]))
            return super.all(params)

        return (await super.allUnlimited(params)).filter(it => !it.intern)
    }

    override dispatchEvent<L extends MandantRepositoryEvents>(type: L, data: MandantRepositoryDispatchMap[L]): any {
        //@ts-expect-error change definition
        return super.dispatchEvent(type, data)
    }

    override addEventListener<L extends MandantRepositoryEvents>(type: L, cb: MandantRepositoryEventMap[L]): any {
        //@ts-expect-error change definition
        return super.addEventListener(type, cb)
    }

    override removeEventListener<L extends MandantRepositoryEvents>(type: L, cb: MandantRepositoryEventMap[L]): any {
        //@ts-expect-error change definition
        return super.removeEventListener(type, cb)
    }

    async mandantList<K>(params: K): Promise<PartitionedResultSet<MandantListElement, MandantListDistinct>> {
        const result = await this.api.get<PartitionedResultSet<MandantListElement, MandantListDistinct>>(this.route("mandantenliste"), params)
        return new PartitionedResultSet(MandantListElement, MandantListDistinct, result)
    }

    async temporaererSachbearbeiter(mandantIds: Id<Mandant>[], sachbearbeiter: Id<Mitarbeiter> | null): Promise<number> {
        const count = await this.api.post<number>(
            this.route("temporaerersachbearbeiter"),
            new MandantTemporaererSachbearbeiterCommandBody(
                mandantIds,
                sachbearbeiter,
            ),
        )
        await this.dispatchEvent("temporaerersachbearbeiter", undefined)
        return count
    }

    async abschliessen(id: Id<Mandant>, abrechnungszeitraum: LocalMonth): Promise<Mandant> {
        const result = await this.api.post<RecordOf<Mandant>>(this.route(`${id}/abschluss`), {abrechnungszeitraum: abrechnungszeitraum})
        const mandant = this.deserialize(result)
        this.dispatchEvent("abschluss", mandant)
        return mandant
    }

    async anschriftentraeger(id: Id<Mandant>, query: MandantAnschriftentraegerQuery = {}): Promise<Blob> {
        return await this.api.getBlob(this.route(`${id}/anschriftentraeger`), query)
    }

    async erzeugeUndVerlinkeDebitor(id: Id<Mandant>, debitorBuilder: DebitorBuilder): Promise<Debitor> {
        const result = await this.api.post<IDebitor>(this.route(`${id}/mandantdebitor`), debitorBuilder)
        return from(Debitor, result)
    }

    async verlinkeSammelrechnung(id: Id<Mandant>, sammelrechnung: Id<Kooperationspartner> | null): Promise<void> {
        await this.api.post(this.route(`${id}/sammelrechnung`), {sammelrechnung: sammelrechnung})
    }

    async aktualisiereAutomatischeRechnung(id: Id<Mandant>, automatischeRechnung: AutomatischeRechnung) {
        await this.api.post(this.route(`${id}/automatischerechnung`), {automatischeRechnung})
    }

    async setzeInaktiv(id: Id<Mandant>): Promise<Mandant> {
        const result = await this.api.post<IMandant>(this.route(`${id}/inaktiv`), {})
        return from(Mandant, result)
    }

    async ticketgruppe(id: Id<Mandant>): Promise<Ticketgruppe> {
        const result = await this.api.get<Partial<Ticketgruppe>>(this.route(`${id}/ticketgruppe`))
        return from(Ticketgruppe, result)
    }

    async vertretung(mandanten: Id<Mandant>[], sachbearbeiter: Id<Mitarbeiter> | null) {
        return await this.api.post(this.route(`vertretung`), {
            mandantIds: mandanten,
            sachbearbeiter: sachbearbeiter,
        })
    }

    async artikelkatalog(id: Id<Mandant>): Promise<Id<Artikelkatalog>> {
        const result = await this.api.get<string>(this.route(`${id}/artikelkatalog`))
        return new Id(result)
    }

    async search(text: string, type: string | null): Promise<SearchResult<SearchResultTopic>[]> {
        return (await this.api.get<ISearchResult<SearchResultTopic>[]>("search", {search: text, type: type}))
            .map(result => SearchResult.parse(result))
    }

    async aktiviereInaktivenMandanten(id: Id<Mandant>): Promise<Mandant> {
        const result = await this.api.post<IMandant>(this.route("reactivate"), {id})
        return from(Mandant, result)
    }
}