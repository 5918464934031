import {MenuItem} from "@lib/common/menu/MenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"

export const VersandMenuItem = new MenuItem({
    label: "Versand",
    name: "versand",
    rights: [RightAccess.readable(RightIdentifier.VERSAND)],
    view: () => import("@intranet/view/versand/VersandList.vue"),
    key: "v"
})