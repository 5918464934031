import { IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Serializable } from "@lib/index"
import { IFile, File } from "@generated/dev/api/model/file/File"
import { AddisonExportItem } from "@generated/de/lohn24/model/exportitem/AddisonExportItem"

export interface IAddisonFileAssociationListItem {
	addisonExportItemId: string | null
	file: IFile
	exported: LocalDateTime | null
	queued: LocalDateTime | null
	start: LocalDateTime | null
	kennung: number | null
	zeitraum: string | null
	failureMessage: string | null
}

export class AddisonFileAssociationListItem extends Serializable<AddisonFileAssociationListItem> {
	public addisonExportItemId!: Id<AddisonExportItem> | null
	public file!: File
	public exported!: LocalDateTime | null
	public queued!: LocalDateTime | null
	public start!: LocalDateTime | null
	public kennung!: number | null
	public zeitraum!: LocalMonth | null
	public failureMessage!: string | null

	static _types: Record<keyof IAddisonFileAssociationListItem, IRuntimeTypeInformation> = {
		addisonExportItemId: { type: Id, nullable: true },
		file: { type: File },
		exported: { type: LocalDateTime, nullable: true },
		queued: { type: LocalDateTime, nullable: true },
		start: { type: LocalDateTime, nullable: true },
		kennung: { type: Number, nullable: true },
		zeitraum: { type: LocalMonth, nullable: true },
		failureMessage: { type: String, nullable: true },
	}

	static props: Record<keyof IAddisonFileAssociationListItem, string> = {
		addisonExportItemId: "addisonExportItemId",
		file: "file",
		exported: "exported",
		queued: "queued",
		start: "start",
		kennung: "kennung",
		zeitraum: "zeitraum",
		failureMessage: "failureMessage",
	}

	constructor(_json: IAddisonFileAssociationListItem) {
		super(_json)
	}
}
