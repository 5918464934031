import {ViewModel} from "@lib/common/model/ViewModel"
import {
    BereitstellungOptionZahlungenArt,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {BereitstellungOptionLohn} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import {
    BereitstellungOptionZahlungen,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import {RecordOf} from "@lib/types"
import {_props} from "@lib/common/_props"
import {Id} from "@lib/common/model/Id"
import {Account} from "@generated/dev/api/model/account/Account"
import {Lohnsteueranmeldeperiode} from "@generated/de/lohn24/model/mandant/Lohnsteueranmeldeperiode"
import {Beitragsnachweis} from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import {Formatter} from "@lib/common/Formatter"
import {LocalMonth} from "@lib/common/LocalMonth"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

export class MandantLohnabrechnungViewModel extends ViewModel<MandantLohnabrechnungViewModel> {

    static label: RecordOf<MandantLohnabrechnungViewModel> & Record<string, any> = {
        heading: {
            abrechnung: "Abrechnung",
        },
        abrechnungssystem: "Abrechnungssystem",
        sachbearbeiter: "Sachbearbeiter/in",
        abschlusstag: "Abschlusstag",
        lohnsteueranmeldeperiode: "Lohnsteuer",
        beitragsnachweis: "Beitragsnachweis",
        beitragAbbuchung: "Beiträge werden abgebucht",
        meldetSichVorAbschluss: "Mandant meldet sich vor Abrechnung",
        abrechnungszeitraum: "Abrechnungszeitraum",
    }

    static properties = _props<InstanceType<typeof this>>()

    mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get bereitstellungZahlungenArt(): BereitstellungOptionZahlungenArt[] {

        return this.mandant.bereitstellungZahlungenArt
    }

    set bereitstellungZahlungenArt(options: BereitstellungOptionZahlungenArt[]) {
        this.mandant.bereitstellungZahlungenArt = options
    }

    get bereitstellungLohn(): BereitstellungOptionLohn[] {
        return this.mandant.bereitstellungLohn
    }

    set bereitstellungLohn(options: BereitstellungOptionLohn[]) {
        this.mandant.bereitstellungLohn = options
    }

    get bereitstellungZahlungen(): BereitstellungOptionZahlungen[] {
        return this.mandant.bereitstellungZahlungen
    }

    set bereitstellungZahlungen(options: BereitstellungOptionZahlungen[]) {
        this.mandant.bereitstellungZahlungen = options
    }

    get abrechnungssystem() {
        return this.mandant.abrechnungssystem
    }

    set abrechnungssystem(value: LohnabrechnungSystem) {
        this.mandant.abrechnungssystem = value
    }

    get abrechnungssystemLabel() {
        return Formatter.bereitstellungAbrechnungssystem(this.mandant.abrechnungssystem)
    }

    get abrechnungszeitraum(): Nullable<Date> {
        return this.mandant.abrechnungszeitraum?.dateTime.toJSDate() ?? null
    }

    set abrechnungszeitraum(value: Nullable<Date>) {
        if (value === null)
            return
        this.mandant.abrechnungszeitraum = LocalMonth.fromJSDate(value)
    }

    get abschlusshinweis() {
        return this.mandant.abschlusshinweis
    }

    set abschlusshinweis(value: string) {
        this.mandant.abschlusshinweis = value
    }

    get abschlusstag() {
        return this.mandant.abschlusstag
    }

    set abschlusstag(value: number) {
        this.mandant.abschlusstag = value
    }

    get bearbeitungshinweis() {
        return this.mandant.bearbeitungshinweis
    }

    set bearbeitungshinweis(value: string) {
        this.mandant.bearbeitungshinweis = value
    }

    get beitragAbbuchung() {
        return this.mandant.beitragAbbuchung
    }

    set beitragAbbuchung(value: boolean) {
        this.mandant.beitragAbbuchung = value
    }

    get beitragsnachweis() {
        return this.mandant.beitragsnachweis
    }

    set beitragsnachweis(value: Beitragsnachweis) {
        this.mandant.beitragsnachweis = value
    }

    get lohnsteueranmeldeperiode() {
        return this.mandant.lohnsteueranmeldeperiode
    }

    set lohnsteueranmeldeperiode(value: Lohnsteueranmeldeperiode) {
        this.mandant.lohnsteueranmeldeperiode = value
    }

    get meldetSichVorAbschluss() {
        return this.mandant.meldetSichVorAbschluss
    }

    set meldetSichVorAbschluss(value: boolean) {
        this.mandant.meldetSichVorAbschluss = value
    }

    get sachbearbeiter(): Id<Account> | null {
        return this.mandant.sachbearbeiter
    }

    set sachbearbeiter(value: Id<Account> | null) {
        this.mandant.sachbearbeiter = value
    }

    get versandhinweis() {
        return this.mandant.versandhinweis
    }

    set versandhinweis(value: string) {
        this.mandant.versandhinweis = value
    }
}