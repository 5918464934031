import {TabMenuItem} from "@lib/common/menu/TabMenuItem"
import {computed} from "vue"
import {NewsUngelesenMenuItem} from "@intranet/view/news/ungelesen/NewsUngelesenMenuItem"
import {NewsGesamtMenuItem} from "@intranet/view/news/gesamt/NewsGesamtMenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import {newsUngelesenListController} from "@intranet/view/news/ungelesen/NewsUngelesenListController"
import {unwrapped} from "@lib/common/Functions"

export const NewsMenuItem = new TabMenuItem({
    label: "News",
    name: "news",
    badge: NewsUngelesenMenuItem.badge,
    route: computed(() => {
        const ungelesen = unwrapped(NewsUngelesenMenuItem.route)
        if (newsUngelesenListController.data.length > 0)
            return ungelesen
        return unwrapped(NewsGesamtMenuItem.route)
    }),
    rights: [RightAccess.readable(RightIdentifier.NEWS)],
    children: [],
})