import {Repository} from "@lib/common/repository/Repository"
import {File, IFile} from "@generated/dev/api/model/file/File"
import {from} from "@lib/common/Functions"
import {Id} from "@lib/common/model/Id"
import {Failure, IFailure} from "@generated/dev/api/model/failure/Failure"
import {Pair} from "@lib/common/repository/RepositoryHasManyWithRelation"

export class FileRepository extends Repository<File> {
    constructor() {
        super(File, "file")
    }

    async data(file: Id<File>): Promise<Blob> {
        return await this.api.getBlob(this.route(`/${file}/data`))
    }

    async associationFile(mandant: number | null, timeout: boolean = false, failure: boolean = false): Promise<Pair<File, Failure | null>[]> {
        const result = await this.api.get<Pair<IFile, IFailure | null>[]>(this.route("association/files"), {
            mandant: mandant,
            timeout: timeout,
            failure: failure,
        })
        return result.map(it => new Pair(from(File, it.first), it.second ? from(Failure, it.second) : null))
    }

    async saveDelete(files: File[]): Promise<void> {
        await this.api.post(this.route("delete"), {files: files.map(it => it.id)})
    }
}