export enum TicketStatus {
	NEU = "NEU",
	OFFEN = "OFFEN",
	PENDING_REMINDER = "PENDING_REMINDER",
	GESCHLOSSEN = "GESCHLOSSEN",
	MERGED = "MERGED",
	REMOVED = "REMOVED",
	PENDING_CLOSE = "PENDING_CLOSE",
	PMC_TEMP = "PMC_TEMP",
}
