import {Repository} from "@lib/common/repository/Repository"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {Serializable} from "@lib/common/serializable/Serializable"
import {AddisonExportItemListItem} from "@generated/de/lohn24/endpoint/AddisonExportItemListe/AddisonExportItemListItem"
import {AddisonExportItem} from "@generated/de/lohn24/model/exportitem/AddisonExportItem"

export class AddisonExportItemDistinct extends Serializable<AddisonExportItemDistinct> {

}

export class AddisonExportItemRepository extends Repository<AddisonExportItem> {

    constructor() {
        super(AddisonExportItem, "exportitem")
    }

    async list<K>(params: K): Promise<PartitionedResultSet<AddisonExportItemListItem, AddisonExportItemDistinct>> {
        const result = await this.api.get<PartitionedResultSet<AddisonExportItemListItem, AddisonExportItemDistinct>>(this.route("liste"), params)
        return new PartitionedResultSet(AddisonExportItemListItem, AddisonExportItemDistinct, result)
    }
}

