import {Currency} from "@lib/common/Currency"
import libphoneParse from "libphonenumber-js"
import {DateTime} from "luxon"
import {LocalMonth} from "@lib/common/LocalMonth"
import {LocalDate} from "@lib/common/LocalDate"
import {Account} from "@generated/dev/api/model/account/Account"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

export const Formatter = {
    mitarbeiter: {
        vorUndZu(mitarbeiter?: { vorname: string, nachname: string }): string {
            return mitarbeiter ? `${mitarbeiter.vorname} ${mitarbeiter.nachname}` : ""
        },
    },
    account: {
        vorUndZu(account?: Account): string | null {
            return (account?.vorname && account?.nachname) ?
                Formatter.mitarbeiter.vorUndZu({
                    vorname: account.vorname,
                    nachname: account.nachname,
                }) : null
        },
    },
    bereitstellungAbrechnungssystem: (abrechnungssystem?: LohnabrechnungSystem) => {
        switch (abrechnungssystem) {
            case LohnabrechnungSystem.ADDISON:
                return "OneClick"
            case LohnabrechnungSystem.AGENDA:
                return "AOL"
            case LohnabrechnungSystem.DATEV:
                return "Unternehmen Online"
            default:
                return "System"
        }
    },

    preis(value: number | undefined, currency: Currency = Currency.EURO): string {
        if (value === undefined)
            return ""
        return `${value.toFixed(2)} ${currency}`
    },
    telefon: {
        formatInternational(phonenumber: string) {
            return libphoneParse(phonenumber, "DE")?.formatInternational() ?? phonenumber
        },
        formatNational(phonenumber: string) {
            return libphoneParse(phonenumber, "DE")?.formatNational() ?? phonenumber
        },
    },
    zeit: {
        /**
         *
         * @returns dd.MMM.yyyy
         */
        datumMonthShort(value: unknown, fromFormat?: string): string {
            if (value instanceof DateTime) {
                return value.setLocale("de-de").toLocaleString({
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                })
            }
            if (value instanceof Date) {
                return this.datumMonthShort(DateTime.fromJSDate(value))
            }
            if (value instanceof LocalMonth) {
                return this.datumMonthShort(value.dateTime)
            }
            if (value instanceof LocalDate) {
                return this.datumMonthShort(value.dateTime)
            }
            if (fromFormat && typeof value === "string") {
                return this.datumMonthShort(DateTime.fromFormat(value, fromFormat))
            }
            return ""
        },
        /**
         *
         * @returns "dd.MM.yyyy HH:mm"
         */
        datumMitZeit(value: unknown, fromFormat?: string): string {
            if (value instanceof DateTime) {
                return value.setLocale("de-de").toLocaleString({
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    hourCycle: "h23",
                    minute: "2-digit",
                }).replace(",", "") + " Uhr"
                // return value.setLocale("de-de").toLocaleString(DateTime.DATETIME_SHORT)
                // return value.toFormat("dd.MM.yyyy hh:mm", {locale: "de-de"})
            }
            if (value instanceof Date) {
                return this.datumMitZeit(DateTime.fromJSDate(value))
            }
            if (value instanceof LocalMonth) {
                return this.datumMitZeit(value.dateTime)
            }
            if (value instanceof LocalDate) {
                return this.datumMonthShort(value.dateTime)
            }
            if (fromFormat && typeof value === "string") {
                return this.datumMitZeit(DateTime.fromFormat(value, fromFormat))
            }
            return ""
        },
        /**
         *
         * @returns "dd.MM.yyyy"
         */
        datum(value: unknown, fromFormat?: string): string {
            if (value instanceof DateTime) {
                return value.toFormat("dd.MM.yyyy")
            }
            if (value instanceof Date) {
                return this.datum(DateTime.fromJSDate(value))
            }
            if (value instanceof LocalMonth) {
                return this.datum(value.dateTime)
            }
            if (value instanceof LocalDate) {
                return this.datum(value.dateTime)
            }
            if (fromFormat && typeof value === "string") {
                return this.datum(DateTime.fromFormat(value, fromFormat))
            }
            return ""
        },
        /**
         *
         * @returns "MM.yyyy"
         */
        zeitraum(value: unknown, fromFormat?: string): string {
            if (value instanceof DateTime) {
                return value.toFormat("MM.yyyy")
            }
            if (value instanceof LocalMonth) {
                return this.zeitraum(value.dateTime)
            }
            if (value instanceof Date) {
                return this.zeitraum(DateTime.fromJSDate(value))
            }
            if (value instanceof LocalDate) {
                return this.datumMonthShort(value.dateTime)
            }
            if (fromFormat && typeof value === "string") {
                return this.zeitraum(DateTime.fromFormat(value, fromFormat))
            }
            return ""
        },
    },
}
