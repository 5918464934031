export enum TicketArtikelType {
	EMAIL = "EMAIL",
	SMS = "SMS",
	CHAT = "CHAT",
	FAX = "FAX",
	PHONE = "PHONE",
	TWITTER_STATUS = "TWITTER_STATUS",
	TWITTER_DIRECT_MESSAGE = "TWITTER_DIRECT_MESSAGE",
	FACEBOOK_FEED_POST = "FACEBOOK_FEED_POST",
	FACEBOOK_FEED_COMMENT = "FACEBOOK_FEED_COMMENT",
	NOTE = "NOTE",
	WEB = "WEB",
	TELEGRAM_PERSONAL_MESSAGE = "TELEGRAM_PERSONAL_MESSAGE",
	FACEBOOK_DIRECT_MESSAGE = "FACEBOOK_DIRECT_MESSAGE",
	PMC_TMP = "PMC_TMP",
}
