import {DialogController} from "@lib/common/controller/DialogController"
import {computed, onUnmounted, reactive, Ref, shallowRef, ShallowRef, WatchStopHandle} from "vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {MandantEditDialogViewModel} from "@intranet/view/mandant/edit/MandantEditDialogViewModel"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {KontaktListController} from "@intranet/view/kontakt/KontaktListController"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {MandantArtikelListController} from "@intranet/view/mandant/artikel/MandantArtikelListController"
import {
    MandantBenachrichtigungsoptionListController,
} from "@intranet/view/mandant/benachrichtigung/MandantBenachrichtigungsoptionListController"
import {MandantViewModel} from "@intranet/view/mandant/MandantViewModel"
import {ValidationModel, Validator} from "@lib/common/Validator"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {AnschriftController} from "@intranet/view/anschrift/AnschriftController"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {MandantModelController} from "@intranet/view/mandant/MandantModelController"
import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {OptionalAnschriftController} from "@intranet/view/anschrift/OptionalAnschriftController"
import {DebitorBuilderController} from "@intranet/view/debitor/builder/DebitorBuilderController"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {
    MandantArtikelTab,
    MandantBenachrichtigungTab,
    MandantDebitorTab,
    MandantLohnabrechnungTab,
    MandantOrganisationTab,
    MandantStammdatenTab,
} from "@intranet/view/mandant/edit/MandantEditTabs"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {ElMessage} from "element-plus"

export class MandantEditDialogController extends DialogController {
    title: Ref<string> = computed(() => {
        return this.viewModel.titel
    })
    mandantController: ShallowRef<MandantModelController>
    debitorController: ShallowRef<DebitorController<Mandant> | null> = shallowRef(null)
    organisationKontaktController: ShallowRef<KontaktListController<Organisation> | null> = shallowRef(null)
    mandantKooperationspartnerKontaktController: ShallowRef<KontaktListController<Kooperationspartner> | null> = shallowRef(null)
    mandantArtikelController: ShallowRef<MandantArtikelListController | null> = shallowRef(null)
    benachrichtigungoptionController: ShallowRef<MandantBenachrichtigungsoptionListController | null> = shallowRef(null)
    anschriftController: ShallowRef<AnschriftController<Mandant> | null> = shallowRef(null)
    postanschriftController: ShallowRef<OptionalAnschriftController<Debitor> | null> = shallowRef(null)

    kontakte = shallowRef<Kontakt[]>([])
    tabs = reactive([
        new MandantStammdatenTab(),
        new MandantDebitorTab(() => !this.mandantController.value.needsDebitor ?? false),
        new MandantOrganisationTab(),
        new MandantBenachrichtigungTab(),
        new MandantLohnabrechnungTab(),
        new MandantArtikelTab(),
    ])
    validator = computed(() => {
        return new Validator(
            new ValidationModel(
                "mandant",
                "mandant",
                () => this.mandant,
            ),
            new ValidationModel(
                "debitor",
                "debitor",
                () => this.debitorController?.value?.model?.value?.debitor ?? null,
                () => !this.mandantController.value.needsDebitor,
            ),
            new ValidationModel(
                "anschrift",
                "rechnungsanschrift",
                () => this.anschriftController.value?.model?.value?.anschrift ?? null,
                () => !this.anschriftController.value,
            ),
            new ValidationModel(
                "anschrift",
                "postanschrift",
                () => this.postanschriftController.value?.model?.value?.anschrift ?? null,
                () => ((this.postanschriftController.value?.isEmpty ?? true)
                    || (this.postanschriftController.value?.isCleared ?? true)),
            ),
            ...computed<ValidationModel<MandantArtikel>[]>(() => {
                return this.mandantArtikelController.value
                        ?.data.map(viewModel => {
                            return new ValidationModel(
                                "mandantartikel",
                                `mandantartikel.${viewModel.validationIndex}`,
                                () => viewModel.mandantArtikel,
                            )
                        })
                    ?? [] as ValidationModel<MandantArtikel>[]
            }).value,
        )
    })
    private watchStopHandle: null | WatchStopHandle = null

    constructor(mandant: Mandant) {
        super()
        this.mandantController = shallowRef(new MandantModelController(new MandantViewModel(mandant)))
    }

    get mandant(): Mandant {
        return this.mandantController.value.model.value.mandant
    }

    get debitor() {
        return this.debitorController.value?.model.value.debitor
    }

    private _artikelkatalog: ShallowRef<Id<Artikelkatalog> | null> = shallowRef(null)

    get artikelkatalog() {
        return this._artikelkatalog.value
    }

    set artikelkatalog(value) {
        this._artikelkatalog.value = value
    }

    get viewModel() {
        return new MandantEditDialogViewModel(this.mandant)
    }

    override modified(): boolean {
        return (this.mandantController.value?.modified || false)
            || (this.debitorController.value?.modified || false)
            || (this.anschriftController.value?.modified || false)
            || (this.postanschriftController.value?.modified || false)
            || (this.organisationKontaktController.value?.modified || false)
            || (this.mandantArtikelController.value?.modified || false)
            || (this.benachrichtigungoptionController.value?.modified || false)
    }

    updateKontakte(kontakte: Kontakt[]) {
        this.kontakte.value = kontakte
    }

    override async save() {
        try {
            let debitor: Debitor | null = null
            if (this.mandantController.value.needsDebitor) {
                if (this.mandant.debitor === null) {
                    debitor = await this.buildAndLinkDebitor()
                } else {
                    if (this.debitorController.value) {
                        const debitorViewModel = await (this.debitorController.value.update(this.mandant.id))
                        debitor = debitorViewModel.debitor
                    } else {
                        debitor = await Resource.debitor.find(Id.required(this.mandant.debitor))
                    }
                }
            }
            if (this.mandantController.value.model.value.diffKeys().includesAny("sammelrechnung")) {
                await Resource.mandant.verlinkeSammelrechnung(this.mandant.id, this.mandant.sammelrechnung)
            }
            this.mandant.debitor = debitor?.id ?? null
            const mandant = await this.mandantController.value.update()
            if (mandant) {
                if (debitor) {
                    if (debitor.postanschrift == null)
                        this.postanschriftController.value?.create(debitor.id)
                    else
                        await this.postanschriftController.value?.update(debitor.id)
                }
                await this.anschriftController.value?.update(mandant.mandant.id)
                await this.organisationKontaktController.value?.update()
                const organisationskontakte = this.organisationKontaktController.value?.data ?? []
                const kooperationskontakt = this.mandantKooperationspartnerKontaktController.value?.data ?? []
                const kontakte = [...organisationskontakte.map(it => it.kontakt), ...kooperationskontakt.map(it => it.kontakt)]
                if (kontakte.isNotEmpty())
                    await this.benachrichtigungoptionController.value?.update(kontakte)

                await this.mandantArtikelController.value?.update()
            }
            await this.onClose()
        } catch (e) {
            if (e instanceof ValidationError) {
                // es gibt einen Fall bei dem Debitordaten nicht Valide sind. Dafür muss
                // dieser geladen und validiert werden.
                if (this.debitorController.value === null) {
                    const relation = Resource.mandant.debitor
                    const debitor = await relation.firstOrNull(this.mandant.id)
                    if (debitor)
                        this.debitorController.value = DebitorController.build(
                            debitor,
                            relation,
                        )
                }

                await this.validator.value.validate()

                e.response?.data.forEach(it => {
                    ElMessage.error(it.message)
                })
                return undefined
            } else {
                throw e
            }
        }
    }

    subscribe() {
        this.watchStopHandle && this.watchStopHandle()
    }

    override async onClose(): Promise<void> {
        if (ComponentRenderer.activeInstanceIsOf(MandantSummaryDialog)) {
            return super.onClose()
        } else {
            await MandantSummaryDialog.show(this.mandant.id)
        }
        Validator.clearValidationState()
    }

    unsubscribe() {
        this.watchStopHandle && this.watchStopHandle()
        this.watchStopHandle = null
    }

    override useController(): void {
        super.useController()
        this.subscribe()
        onUnmounted(() => {
            this.unsubscribe()
        })
    }

    private async buildAndLinkDebitor(): Promise<Debitor | null> {
        if (this.debitorController.value) {
            const builderController = new DebitorBuilderController(
                this.debitorController as ShallowRef<DebitorController<any>>,
                this.anschriftController as ShallowRef<AnschriftController<any>>,
                this.postanschriftController as ShallowRef<OptionalAnschriftController<any>>,
            )
            return await Resource.mandant.erzeugeUndVerlinkeDebitor(this.mandant.id, builderController.getBuilder())
        }
        return null
    }
}