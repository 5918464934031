import { IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Serializable } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { IFailure, Failure } from "@generated/dev/api/model/failure/Failure"
import { AddisonExportPriority } from "@generated/de/lohn24/model/exportitem/AddisonExportPriority"
import { AddisonExportItemType } from "@generated/de/lohn24/model/exportitem/AddisonExportItemType"
import { AddisonExportItem } from "@generated/de/lohn24/model/exportitem/AddisonExportItem"

export interface IAddisonExportItemListItem {
	exportId: string
	mandant: string
	type: AddisonExportItemType
	priority: AddisonExportPriority
	failure: IFailure | null
	created: LocalDateTime | null
	endzeit: LocalDateTime | null
	startzeit: LocalDateTime | null
	order: number
	zeitraum: string | null
	avarageDuration: number | null
	dauerMs: number | null
	origin: string
}

export class AddisonExportItemListItem extends Serializable<AddisonExportItemListItem> {
	public exportId!: Id<AddisonExportItem>
	public mandant!: Id<Mandant>
	public type!: AddisonExportItemType
	public priority!: AddisonExportPriority
	public failure!: Failure | null
	public created!: LocalDateTime | null
	public endzeit!: LocalDateTime | null
	public startzeit!: LocalDateTime | null
	public order!: number
	public zeitraum!: LocalMonth | null
	public avarageDuration!: number | null
	public dauerMs!: number | null
	public origin!: string

	static _types: Record<keyof IAddisonExportItemListItem, IRuntimeTypeInformation> = {
		exportId: { type: Id },
		mandant: { type: Id },
		type: { type: AddisonExportItemType },
		priority: { type: AddisonExportPriority },
		failure: { type: Failure, nullable: true },
		created: { type: LocalDateTime, nullable: true },
		endzeit: { type: LocalDateTime, nullable: true },
		startzeit: { type: LocalDateTime, nullable: true },
		order: { type: Number },
		zeitraum: { type: LocalMonth, nullable: true },
		avarageDuration: { type: Number, nullable: true },
		dauerMs: { type: Number, nullable: true },
		origin: { type: String },
	}

	static props: Record<keyof IAddisonExportItemListItem, string> = {
		exportId: "exportId",
		mandant: "mandant",
		type: "type",
		priority: "priority",
		failure: "failure",
		created: "created",
		endzeit: "endzeit",
		startzeit: "startzeit",
		order: "order",
		zeitraum: "zeitraum",
		avarageDuration: "avarageDuration",
		dauerMs: "dauerMs",
		origin: "origin",
	}

	constructor(_json: IAddisonExportItemListItem) {
		super(_json)
	}
}
