<script lang="ts" setup>
import {computed, ref, watch} from "vue"
import IconDelete from "@lib/view/icons/IconDelete.vue"
import IconAdd from "@lib/view/icons/IconAdd.vue"
import IconRechnung from "@lib/view/icons/IconRechnung.vue"
import {ResourceCache} from "@lib/model/Resource"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {BelegBuilderDialogController} from "@intranet/view/rechnung/builder/BelegBuilderDialogController"
import {BelegBuilderModus} from "@intranet/view/rechnung/builder/BelegBuilderModus"
import KatalogArtikelPositionTable from "@intranet/view/rechnung/builder/KatalogArtikelPositionTable.vue"
import FreitextArtikelPositionTable from "@intranet/view/rechnung/builder/FreitextArtikelPositionTable.vue"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import CustomDialogV2 from "@lib/view/dialog/CustomDialogV2.vue"
import Debounced from "@lib/view/Debounced.vue"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {ElMessage} from "element-plus"
import {auth} from "@lib/common/Authentication"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import {RightAccess} from "@lib/model/role/RoleRight"

const props = withDefaults(
    defineProps<ComponentRendererProps<BelegBuilder, BelegBuilderDialogController>>(),
    {
        onVanish: ComponentRenderer.onVanishDefault,
        __loader: ComponentRenderer.loaderDefault,
    },
)

const warning = computed(() => {
    return []
    // return props.controller.belegBuilder.itemsNotInKatalog.value.map(it => {
    //   const artikel = ResourceCache.artikel.mapped(it.artikel.required().value, a => a).value
    //   return `"${it.text || artikel?.name}" stammt aus dem Katalog "${artikel && ResourceCache.artikelKatalog
    //       .mapped(artikel.katalog.required().value, k => k.name).value}`
    // })
})

const rechnungsabschluss = computed(() => props.controller.modus === BelegBuilderModus.RECHNUNGSABSCHLUSS)

const sachbearbeiterabschluss = computed(() => props.controller.modus === BelegBuilderModus.SACHBEARBEITERABSCHLUSS)

const manuell = computed(() => props.controller.modus === BelegBuilderModus.MANUELL)

const sammelrechnung = computed(() => props.controller.modus === BelegBuilderModus.SAMMELRECHNUNG)

const visible = computed(() => props.controller.visible.value)

const rechnungswesen = computed(() => auth.hasAccess([RightAccess.full(RightIdentifier.RECHNUNGSWESEN)]))
const builderIstLoeschbar = computed(() => props.controller.belegBuilder.model.value.isLoeschbar)

async function speichern() {
    try {
        await props.controller.zwischenspeichern()
        await props.controller.close()
        await (props.done && props.done(null))
    } catch (e) {
        if (e instanceof ValidationError) {
            ElMessage.error(`Speichern fehlgeschlagen: ${e.response!.data.first().message}`)
        } else {
            throw e
        }
    }

}

async function submit() {
    if (await props.controller.submit()) {
        await (props.done && props.done(null))
    }
}

async function angefangenenBelegLoeschen() {
    const builder = props.controller.belegBuilder.model.value
    if (builder.isLoeschbar) {
        await props.controller.loeschen()
        await props.controller.close()
        await (props.done && props.done(null))
    }
}

const artikelkatalog = ref<Artikelkatalog>()
watch(() => props.controller.belegBuilder.model.value.builder.artikelkatalog?.value, async id => {
    artikelkatalog.value = (await ResourceCache.artikelkatalog.all())
        .find(it => it.id.value === id)
}, {immediate: true})


</script>

<template>
  <custom-dialog-v2
          v-model="props.controller.visible.value"
          :close="() => props.controller.close()"
          :title="props.controller.title.value"
          intent="beleg"
  >
    <template #header-right>
      <el-tooltip :content="`Artikelkatalog: ${artikelkatalog?.name || artikelkatalog?.id}`" placement="top">
        <el-button icon="InfoFilled" text />
      </el-tooltip>
      <el-tooltip v-if="sachbearbeiterabschluss && rechnungswesen" content="Angefangenen Beleg löschen" placement="top">
        <el-button text @click="angefangenenBelegLoeschen()" :disabled="!builderIstLoeschbar">
          <icon-delete />
        </el-button>
      </el-tooltip>
      <el-tooltip v-if="rechnungsabschluss" content="Rechnung verwerfen" placement="top">
        <el-button text @click="controller.verwerfen()">
          <icon-delete />
        </el-button>
      </el-tooltip>
      <el-tooltip content="Individuellen Artikel hinzufügen" placement="top">
        <el-button
                data-cy="freitextartikel-add"
                text
                @click="controller.belegBuilder.addFreitextArtikel()"
        >
          <icon-add />
        </el-button>
      </el-tooltip>
      <el-tooltip v-if="rechnungsabschluss" content="Vorschau" placement="top">
        <el-button text @click="controller.vorschau()">
          <icon-rechnung />
        </el-button>
      </el-tooltip>
    </template>
    <template #content>
      <el-row v-if="warning.length>0" style="padding-bottom: 20px">
        <el-alert type="error">Es sind Artikel aus einem anderen Artikelkatalog hinterlegt: <br>{{ warning.join(", ") }}
        </el-alert>
        <el-col></el-col>
      </el-row>
      <katalog-artikel-position-table
              v-else
              :controller="controller.belegBuilder"
              :preise="!sachbearbeiterabschluss"
              createable
              deletable
      />
      <hr v-if="controller.belegBuilder.freitextArtikelPositionen.value.length > 0">
      <freitext-artikel-position-table
              v-if="controller.belegBuilder.freitextArtikelPositionen.value.length > 0"
              :controller="controller.belegBuilder"
              :manuell="manuell"
              :preise="!sachbearbeiterabschluss"
      />
    </template>
    <template #footer>
      <debounced :wait="500">
        <template #default="{debounce}">
          <el-button data-cy="save" name="speichern" @click="debounce(speichern)">
            Speichern
          </el-button>
        </template>
      </debounced>
      <debounced :wait="900">
        <template #default="{debounce}">
          <el-button data-cy="submit" name="submit" type="primary" @click="debounce(submit)">
            {{ controller.submitText }}
          </el-button>
        </template>
      </debounced>

    </template>
  </custom-dialog-v2>
</template>

<style lang="scss">
.rechnungserstellung-dialog {
  .hidden {
    .cell {
      visibility: hidden;
    }
  }
}
</style>