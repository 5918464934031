import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { Ticketgruppe } from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import { TicketStatus } from "@generated/de/lohn24/model/ticket/TicketStatus"
import { Organisation } from "@generated/de/lohn24/model/organisation/Organisation"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Kontakt } from "@generated/de/lohn24/model/kontakt/Kontakt"
import { Account } from "@generated/dev/api/model/account/Account"

export interface ITicket extends IModel {
	besitzer: string | null
	kontakt: string | null
	kooperationspartner: string | null
	mandant: string | null
	organisation: string | null
	ticketgruppe: string | null
	deleted: LocalDateTime | null
	erstellt: LocalDateTime
	synchronisiert: LocalDateTime | null
	zugewiesen: LocalDateTime | null
	imAuftragVon: number | null
	nummer: number
	zammadId: number
	zammadOrganizationId: number | null
	mandantennummer: string | null
	absender: string
	betreff: string
	debitorennummer: string | null
	status: TicketStatus
}

export class Ticket extends Model<Ticket> {
	public besitzer!: Id<Account> | null
	public kontakt!: Id<Kontakt> | null
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public organisation!: Id<Organisation> | null
	public ticketgruppe!: Id<Ticketgruppe> | null
	public deleted!: LocalDateTime | null
	public erstellt!: LocalDateTime
	public synchronisiert!: LocalDateTime | null
	public zugewiesen!: LocalDateTime | null
	public imAuftragVon!: number | null
	public nummer!: number
	public zammadId!: number
	public zammadOrganizationId!: number | null
	public mandantennummer!: string | null
	public absender!: string
	public betreff!: string
	public debitorennummer!: string | null
	public status!: TicketStatus

	static _types: Record<keyof ITicket, IRuntimeTypeInformation> = {
		id: { type: Id },
		besitzer: { type: Id, nullable: true },
		kontakt: { type: Id, nullable: true },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		organisation: { type: Id, nullable: true },
		ticketgruppe: { type: Id, nullable: true },
		deleted: { type: LocalDateTime, nullable: true },
		erstellt: { type: LocalDateTime },
		synchronisiert: { type: LocalDateTime, nullable: true },
		zugewiesen: { type: LocalDateTime, nullable: true },
		imAuftragVon: { type: Number, nullable: true },
		nummer: { type: Number },
		zammadId: { type: Number },
		zammadOrganizationId: { type: Number, nullable: true },
		mandantennummer: { type: String, nullable: true },
		absender: { type: String },
		betreff: { type: String },
		debitorennummer: { type: String, nullable: true },
		status: { type: TicketStatus },
	}

	static props: Record<keyof ITicket, string> = {
		id: "id",
		besitzer: "besitzer",
		kontakt: "kontakt",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		organisation: "organisation",
		ticketgruppe: "ticketgruppe",
		deleted: "deleted",
		erstellt: "erstellt",
		synchronisiert: "synchronisiert",
		zugewiesen: "zugewiesen",
		imAuftragVon: "imAuftragVon",
		nummer: "nummer",
		zammadId: "zammadId",
		zammadOrganizationId: "zammadOrganizationId",
		mandantennummer: "mandantennummer",
		absender: "absender",
		betreff: "betreff",
		debitorennummer: "debitorennummer",
		status: "status",
	}

	constructor(_json: ITicket) {
		super(_json)
	}
}
