import { IModel, IRuntimeTypeInformation, Id, LocalDate, LocalMonth, Model } from "@lib/index"
import { Organisation } from "@generated/de/lohn24/model/organisation/Organisation"
import { MandantStatusPortal } from "@generated/de/lohn24/model/mandant/MandantStatusPortal"
import { MandantStatus } from "@generated/de/lohn24/model/mandant/MandantStatus"
import { Lohnsteueranmeldeperiode } from "@generated/de/lohn24/model/mandant/Lohnsteueranmeldeperiode"
import { LohnabrechnungSystem } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"
import { Kuendigungsgrund } from "@generated/de/lohn24/model/mandant/Kuendigungsgrund"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { BereitstellungOptionZahlungenArt } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import { BereitstellungOptionZahlungen } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import { BereitstellungOptionLohn } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import { Beitragsnachweis } from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import { AutomatischeRechnung } from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import { Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"
import { AddisonMandantExport } from "@generated/de/lohn24/model/mandant/export/AddisonMandantExport"
import { AddisonGlobalExport } from "@generated/de/lohn24/model/mandant/export/AddisonGlobalExport"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IMandant extends IModel {
	anschrift: string
	debitor: string | null
	effektiverSachbearbeiter: string | null
	globalExport: string | null
	kooperationspartner: string | null
	mandantExport: string | null
	organisation: string
	rechnungssteller: string
	sachbearbeiter: string | null
	sammelrechnung: string | null
	temporaererSachbearbeiter: string | null
	vertretung: string | null
	vorherigerSachbearbeiter: string | null
	automatischeRechnung: AutomatischeRechnung
	beitragsnachweis: Beitragsnachweis
	beitragAbbuchung: boolean
	direktvertrag: boolean
	gesperrt: boolean
	intern: boolean
	keinDirektkontakt: boolean
	keineRechnung: boolean
	lohncall: boolean
	meldetSichVorAbschluss: boolean
	bereitstellungLohn: Array<BereitstellungOptionLohn>
	bereitstellungZahlungen: Array<BereitstellungOptionZahlungen>
	bereitstellungZahlungenArt: Array<BereitstellungOptionZahlungenArt>
	abschlusstag: number
	klassifizierung: number | null
	kuendigungsgrund: Kuendigungsgrund | null
	kuendigungsdatum: LocalDate | null
	abrechnungszeitraum: string
	abrechnungssystem: LohnabrechnungSystem
	lohnsteueranmeldeperiode: Lohnsteueranmeldeperiode
	kennung: string
	status: MandantStatus
	statusPortal: MandantStatusPortal
	abschlusshinweis: string
	bearbeitungshinweis: string
	betriebsnummer: string | null
	gesperrtNotiz: string | null
	kuendigungsgrundNotiz: string | null
	name: string
	statusNotiz: string | null
	steuernummer: string | null
	versandhinweis: string
}

export class Mandant extends Model<Mandant> {
	public anschrift!: Id<Anschrift>
	public debitor!: Id<Debitor> | null
	public effektiverSachbearbeiter!: Id<Account> | null
	public globalExport!: Id<AddisonGlobalExport> | null
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandantExport!: Id<AddisonMandantExport> | null
	public organisation!: Id<Organisation>
	public rechnungssteller!: Id<Firma>
	public sachbearbeiter!: Id<Account> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public temporaererSachbearbeiter!: Id<Account> | null
	public vertretung!: Id<Account> | null
	public vorherigerSachbearbeiter!: Id<Account> | null
	public automatischeRechnung!: AutomatischeRechnung
	public beitragsnachweis!: Beitragsnachweis
	public beitragAbbuchung!: boolean
	public direktvertrag!: boolean
	public gesperrt!: boolean
	public intern!: boolean
	public keinDirektkontakt!: boolean
	public keineRechnung!: boolean
	public lohncall!: boolean
	public meldetSichVorAbschluss!: boolean
	public bereitstellungLohn!: Array<BereitstellungOptionLohn>
	public bereitstellungZahlungen!: Array<BereitstellungOptionZahlungen>
	public bereitstellungZahlungenArt!: Array<BereitstellungOptionZahlungenArt>
	public abschlusstag!: number
	public klassifizierung!: number | null
	public kuendigungsgrund!: Kuendigungsgrund | null
	public kuendigungsdatum!: LocalDate | null
	public abrechnungszeitraum!: LocalMonth
	public abrechnungssystem!: LohnabrechnungSystem
	public lohnsteueranmeldeperiode!: Lohnsteueranmeldeperiode
	public kennung!: string
	public status!: MandantStatus
	public statusPortal!: MandantStatusPortal
	public abschlusshinweis!: string
	public bearbeitungshinweis!: string
	public betriebsnummer!: string | null
	public gesperrtNotiz!: string | null
	public kuendigungsgrundNotiz!: string | null
	public name!: string
	public statusNotiz!: string | null
	public steuernummer!: string | null
	public versandhinweis!: string

	static _types: Record<keyof IMandant, IRuntimeTypeInformation> = {
		id: { type: Id },
		anschrift: { type: Id },
		debitor: { type: Id, nullable: true },
		effektiverSachbearbeiter: { type: Id, nullable: true },
		globalExport: { type: Id, nullable: true },
		kooperationspartner: { type: Id, nullable: true },
		mandantExport: { type: Id, nullable: true },
		organisation: { type: Id },
		rechnungssteller: { type: Id },
		sachbearbeiter: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		temporaererSachbearbeiter: { type: Id, nullable: true },
		vertretung: { type: Id, nullable: true },
		vorherigerSachbearbeiter: { type: Id, nullable: true },
		automatischeRechnung: { type: AutomatischeRechnung },
		beitragsnachweis: { type: Beitragsnachweis },
		beitragAbbuchung: { type: Boolean },
		direktvertrag: { type: Boolean },
		gesperrt: { type: Boolean },
		intern: { type: Boolean },
		keinDirektkontakt: { type: Boolean },
		keineRechnung: { type: Boolean },
		lohncall: { type: Boolean },
		meldetSichVorAbschluss: { type: Boolean },
		bereitstellungLohn: { type: [BereitstellungOptionLohn] },
		bereitstellungZahlungen: { type: [BereitstellungOptionZahlungen] },
		bereitstellungZahlungenArt: { type: [BereitstellungOptionZahlungenArt] },
		abschlusstag: { type: Number },
		klassifizierung: { type: Number, nullable: true },
		kuendigungsgrund: { type: Kuendigungsgrund, nullable: true },
		kuendigungsdatum: { type: LocalDate, nullable: true },
		abrechnungszeitraum: { type: LocalMonth },
		abrechnungssystem: { type: LohnabrechnungSystem },
		lohnsteueranmeldeperiode: { type: Lohnsteueranmeldeperiode },
		kennung: { type: String },
		status: { type: MandantStatus },
		statusPortal: { type: MandantStatusPortal },
		abschlusshinweis: { type: String },
		bearbeitungshinweis: { type: String },
		betriebsnummer: { type: String, nullable: true },
		gesperrtNotiz: { type: String, nullable: true },
		kuendigungsgrundNotiz: { type: String, nullable: true },
		name: { type: String },
		statusNotiz: { type: String, nullable: true },
		steuernummer: { type: String, nullable: true },
		versandhinweis: { type: String },
	}

	static props: Record<keyof IMandant, string> = {
		id: "id",
		anschrift: "anschrift",
		debitor: "debitor",
		effektiverSachbearbeiter: "effektiverSachbearbeiter",
		globalExport: "globalExport",
		kooperationspartner: "kooperationspartner",
		mandantExport: "mandantExport",
		organisation: "organisation",
		rechnungssteller: "rechnungssteller",
		sachbearbeiter: "sachbearbeiter",
		sammelrechnung: "sammelrechnung",
		temporaererSachbearbeiter: "temporaererSachbearbeiter",
		vertretung: "vertretung",
		vorherigerSachbearbeiter: "vorherigerSachbearbeiter",
		automatischeRechnung: "automatischeRechnung",
		beitragsnachweis: "beitragsnachweis",
		beitragAbbuchung: "beitragAbbuchung",
		direktvertrag: "direktvertrag",
		gesperrt: "gesperrt",
		intern: "intern",
		keinDirektkontakt: "keinDirektkontakt",
		keineRechnung: "keineRechnung",
		lohncall: "lohncall",
		meldetSichVorAbschluss: "meldetSichVorAbschluss",
		bereitstellungLohn: "bereitstellungLohn",
		bereitstellungZahlungen: "bereitstellungZahlungen",
		bereitstellungZahlungenArt: "bereitstellungZahlungenArt",
		abschlusstag: "abschlusstag",
		klassifizierung: "klassifizierung",
		kuendigungsgrund: "kuendigungsgrund",
		kuendigungsdatum: "kuendigungsdatum",
		abrechnungszeitraum: "abrechnungszeitraum",
		abrechnungssystem: "abrechnungssystem",
		lohnsteueranmeldeperiode: "lohnsteueranmeldeperiode",
		kennung: "kennung",
		status: "status",
		statusPortal: "statusPortal",
		abschlusshinweis: "abschlusshinweis",
		bearbeitungshinweis: "bearbeitungshinweis",
		betriebsnummer: "betriebsnummer",
		gesperrtNotiz: "gesperrtNotiz",
		kuendigungsgrundNotiz: "kuendigungsgrundNotiz",
		name: "name",
		statusNotiz: "statusNotiz",
		steuernummer: "steuernummer",
		versandhinweis: "versandhinweis",
	}

	constructor(_json: IMandant) {
		super(_json)
	}
}
