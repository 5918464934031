<script generic="M extends Model<M>" lang="ts" setup>
import {computed, nextTick, onErrorCaptured, ref, unref, watch} from "vue"
import {BaseIdType, Id} from "@lib/common/model/Id"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {AxiosError} from "axios"
import IconAdd from "@lib/view/icons/IconAdd.vue"
import IconEdit from "@lib/view/icons/IconEdit.vue"
import {from} from "@lib/common/Functions"
import {Config} from "@lib/Config"
import {RepositoryOptionsDialog, RepositoryOptionsProps} from "@lib/view/formitem/RepositoryOptionsProps"
import {FontSize} from "@lib/common/Enums"
import {Model} from "@lib/common/model/Model"
import {UuidService} from "@lib/common/uuid/UuidService"

defineOptions({
    inheritAttrs: false,
})

const emits = defineEmits<{
    "update:modelValue": [id: (Id<M> | null) | (Id<M> | null)[]],
    "update:selected": [selected: Id<M>[]],
    "change": [model?: Nullable<M>],
    "dialog:create": []
}>()

const props = withDefaults(
    defineProps<RepositoryOptionsProps<M> & RepositoryOptionsDialog>(),
    {
        // modelValue: () => null,
        placeholder: () => "Leer",
        width: () => "100%",
        modelKey: () => "name",
        buildDialog: "",
        editDialog: "Bearbeiten",
        modelLabel: "Wert",
        size: () => FontSize.small,
        virtual: () => true,
        additionalItems: () => ([]),
        immediate: () => true,
        dataCy: () => "data-cy",
    },
)

const id = computed<Nullable<BaseIdType> | BaseIdType[]>({
    get: () => {
        if (Array.isArray(props.modelValue)) {
            return props.modelValue.mapNotNull(it => it?.value ?? null)
        }
        if (props.modelValue && new Id(props.modelValue) && computedOptions.value.any(it => it.value == props.modelValue))
            return props.modelValue.value
        return null
    },
    set: async (value: (Nullable<BaseIdType> | "") | (BaseIdType | "")[]) => {
        if (Array.isArray(value)) {
            const set = value.map(it => {
                const baseId = it === "" ? null : it
                return baseId ? new Id(baseId) : null
            }).toSet()
            emits("update:modelValue", Array.from(set.values()))
            await nextTick()
            return emits("change")
        }
        const baseId = value === "" ? null : value
        const id = baseId ? new Id<M>(baseId) : null
        updateSelectedList(id)
        emits("update:modelValue", id)
        return emitChange(id)
    },
})

const anzahlButtons = computed(() => Number(props.createable) + Number(props.editable) + Number(props.buildable))
const model = ref<M>()
const showCreateDialog = ref(false)
const showBuildDialog = ref(false)
const showEditDialog = ref(false)
const inputValue = ref("")
const error = ref("")

const builderOption = ref<{ value: unknown, label: string, disabled: boolean }[] | null>(null)
const cache = computed(() => props
    .repository
    .cache
    .map(it => it).value)

const computedOptions = computed<{ value: unknown, label: string, disabled: boolean }[]>(() => {
        return props.options ?? builderOption.value ?? cache
            .value
            .concat(...(props.additionalItems ?? []))
            .filter(it => {
                return props.optionFilter ? props.optionFilter(it) : true
            })
            .sort((a, b) => props.optionSort ? props.optionSort(a, b) : 0)
            .map(it => {
                const value = it.id.value
                const label = props.optionLabel ? props.optionLabel(it) : it[props.modelKey]
                return {
                    value: value,
                    label: label,
                    disabled: props.optionDisabled
                        ? props.optionDisabled(it) || disableOption({value: value, label})
                        : false,
                }
            })
            .sort((a, b) => props.optionSort ? 0 : (a.label > b.label ? 1 : a.label == b.label ? 0 : -1))
    },
)

async function emitChange(id: Id<M> | null) {
    if (id === null)
        return emits("change", null)

    const result = props.repository.cache.mapped(id, it => it).value
        ?? props.additionalItems?.find(it => it.id.value === id.value)
    return emits("change", result)
}

function updateSelectedList(value: Id<M> | null) {
    if (!Array.isArray(props.selected)) return
    if (Array.isArray(props.modelValue)) return
    const newSelected = props.selected.filter((i) => i.value !== (props.modelValue as Nullable<Id<M>>)?.value)
    if (value !== null) newSelected.push(value)
    emits("update:selected", newSelected)
}

function disableOption(item: { label: string; value: unknown }): boolean {
    return Array.isArray(props.selected)
        ? props.selected.findIndex(it => it.value === item.value) >= 0
        : false
}

async function build() {
    const repo = props.repository
    const key = props.modelKey
    if (!key) throw Error(`Invalid key ${key} for ${repo.type}`)
    const model = from(
        repo.type,
        {
            id: UuidService.id().value,
            [key]: inputValue.value,
        },
    )
    // zur einzigen Option machen
    builderOption.value = [{value: model.id.value, label: model[key], disabled: false}]
    emits("update:modelValue", model.id)
    emits("change", model as M)
    showBuildDialog.value = false
    error.value = ""
    inputValue.value = ""
}

async function create(m?: M) {
    const repo = props.repository
    let model
    if (!m) {
        const key = props.modelKey
        if (!key) throw Error(`Invalid key ${key} for ${repo.type}`)
        model = from(
            repo.type,
            {
                [key]: inputValue.value,
            },
        )
    } else {
        model = m
    }

    try {
        const createdModel = await repo.insert(model as M)
        showCreateDialog.value = false
        error.value = ""
        inputValue.value = ""
        emits("update:modelValue", createdModel.id)
        emits("change", createdModel)
    } catch (e) {
        if (e instanceof ValidationError) {
            error.value = `${e.response?.data?.join(", ")}`
        } else if (e instanceof AxiosError) {
            error.value = `${e.response?.status}: ${e.response?.statusText}`
        }
    }
}

async function update(m?: M) {
    if (m) {
        return await updateModel(m)
    }
    const repo = props.repository
    const key = props.modelKey
    if (!key) throw Error(`Invalid key ${key} for ${repo.type}`)
    const id_ = unref(id)
    if (id_ && !Array.isArray(id_)) {
        const item = props.additionalItems?.find(it => it.id.value === id.value)

        if (item) {
            item[key] = inputValue.value
            showEditDialog.value = false
            error.value = ""
            inputValue.value = ""
            return
        }

        try {
            model.value![key] = inputValue.value
            const updatedModel = await repo.update(model.value!)
            showEditDialog.value = false
            error.value = ""
            inputValue.value = ""
            emits("update:modelValue", updatedModel.id)
            emits("change", updatedModel)
        } catch (e) {
            if (e instanceof ValidationError) {
                error.value = `${e.response?.data?.join(", ")}`
            } else if (e instanceof AxiosError) {
                error.value = `${e.response?.status}: ${e.response?.statusText}`
            } else {
                throw e
            }
        }
    }
}

async function updateModel(model: M) {
    try {
        const updatedModel = await props.repository.update(model)
        showEditDialog.value = false
        error.value = ""
        emits("update:modelValue", updatedModel.id)
        emits("change", updatedModel)
    } catch (e) {
        if (e instanceof ValidationError) {
            error.value = `${e.response?.data?.join(", ")}`
        } else if (e instanceof AxiosError) {
            error.value = `${e.response?.status}: ${e.response?.statusText}`
        } else {
            throw e
        }
    }
}

async function pullModel(emitsNull: boolean = true): Promise<M | null> {
    if (Array.isArray(props.modelValue)) return null
    if (props.modelValue === null) return null

    model.value = (await props.repository.cache.mappedAsync(new Id(props.modelValue), it => it)).value
        // await props.repository.find(new Id(props.modelValue))
        ?? props.additionalItems.find(it => it.id.value === id.value)
        ?? null
    if (emitsNull && model.value === null)
        emits("change", model.value)
    return model.value
}

async function openEditDialog() {
    const fresh = await pullModel()
    if (fresh) {
        showEditDialog.value = true
        inputValue.value = fresh[props.modelKey]
    }
}

if (props.invalidateCache) {
    props.repository.cache.invalidate()
}
if (props.immediate) {
    pullModel(false)
}

const ENV = Config

onErrorCaptured((error) => {
    return !(error.message.includes("Cannot read properties of null")
        || error.message.includes("component is null")
        || error.message.includes("menuRef.value is null"))
})

watch(showCreateDialog, visible => {
    if (visible)
        emits("dialog:create")
})

</script>

<template>
  <div :style="(width) ? `width: ${width}` : 'width: 100%'" class="options">
    <el-button-group
            :size="props.size"
            :style="(width) ? `width: ${width}` : 'width: 100%'" class="options"
            @contextmenu.prevent
    >
      <el-select-v2
              v-if="virtual"
              v-model="id"
              :class="{'button-grouped': (createable || editable)}"
              :clearable="clearable"
              :disabled="disabled"
              :multiple="multiple"
              :name="modelKey"
              :options="computedOptions"
              :placeholder="placeholder"
              :size="props.size"
              :style="(createable || editable || buildable) ? `width: calc(${width} - ${(anzahlButtons) * 37}px)` : ''"
              autocomplete="off"
              filterable
              @contextmenu.prevent
      >
      </el-select-v2>
      <el-select
              v-else
              v-model="id"
              :class="{'button-grouped': (createable || editable)}"
              :clearable="clearable"
              :disabled="disabled"
              :multiple="multiple"
              :name="modelKey"
              :placeholder="placeholder"
              :size="props.size"
              :style="(createable || editable || buildable) ? `width: calc(${width} - ${(anzahlButtons) * 37}px)` : ''"
              autocomplete="off"
              filterable
              @contextmenu.prevent
      >
        <el-option
                v-for="item in computedOptions"
                :key="item.value"
                :disabled="item.disabled"
                :label="item.label"
                :size="size"
                :value="item.value"
                @contextmenu.prevent
        />
      </el-select>
      <el-button v-if="editable" :size="props.size" name="edit" @click="openEditDialog()">
        <icon-edit />
      </el-button>
      <el-button v-if="createable || buildable"
                 :size="props.size"
                 name="add"
                 @click="showCreateDialog=createable ?? false; showBuildDialog=(buildable && !createable) ?? false"
      >
        <icon-add />
      </el-button>
    </el-button-group>
    <slot v-if="buildDialog || editDialog" :dialog="showCreateDialog || showEditDialog" :size="size" name="dialog">
      <slot v-if="showCreateDialog"
            :close="() => showCreateDialog = false"
            :dialog="showCreateDialog"
            :size="props.size"
            :title="buildDialog"
            name="create-dialog"
      >
        <el-dialog v-model="showCreateDialog"
                   :data-cy="dataCy + '-create-dialog'"
                   :title="buildDialog"
                   class="options"
                   destroy-on-close
                   width="30%"
                   @contextmenu.stop
                   append-to-body
        >
          <el-form label-width="120" size="small">
            <slot name="create-dialog-form">
              <el-form-item :error="error" :label="modelLabel">
                <el-input v-model="inputValue" :data-cy="dataCy + '-create-dialog-wert'"></el-input>
              </el-form-item>
            </slot>
          </el-form>
          <template #footer>
            <el-button @click="showCreateDialog = false">{{ ENV.buttonLabel.CANCEL_BUTTON_TEXT }}</el-button>
            <slot name="create-dialog-save" :create="(m: M) => create(m)">
              <el-button :data-cy="dataCy + '-create-dialog-save'" type="primary" @click="create()">
                Speichern
              </el-button>
            </slot>
          </template>
        </el-dialog>
      </slot>
      <slot v-if="showBuildDialog"
            :close="() => showBuildDialog = false"
            :dialog="showBuildDialog"
            :size="props.size"
            :title="buildDialog"
            name="build-dialog"
      >
        <el-dialog v-model="showBuildDialog"
                   :data-cy="dataCy + '-build-dialog'"
                   :title="buildDialog"
                   class="options"
                   destroy-on-close
                   width="30%"
                   @contextmenu.stop
        >
          <el-form>
            <el-form-item :error="error" :label="modelLabel">
              <el-input v-model="inputValue" :data-cy="dataCy + '-build-dialog-wert'"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button @click="showBuildDialog = false">{{ ENV.buttonLabel.CANCEL_BUTTON_TEXT }}</el-button>
            <el-button :data-cy="dataCy + '-build-dialog-save'" type="primary" @click="build()">
              Speichern
            </el-button>
          </template>
        </el-dialog>
      </slot>
      <slot v-if="showEditDialog"
            :close="() => showEditDialog = false"
            :dialog="showEditDialog"
            :size="props.size"
            :title="editDialog"
            name="edit-dialog"
      >
        <el-dialog
                v-model="showEditDialog"
                :title="editDialog"
                class="options"
                destroy-on-close
                width="30%"
                @contextmenu.stop
                append-to-body
        >
          <el-form label-width="120" size="small">
            <slot name="edit-dialog-form" :model="model">
              <el-form-item :error="error" :label="modelLabel">
                <el-input v-model="inputValue" :data-cy="dataCy + '-edit-dialog-wert'"></el-input>
              </el-form-item>
            </slot>
          </el-form>
          <template #footer>
            <el-button @click="showEditDialog=false">{{ ENV.buttonLabel.CANCEL_BUTTON_TEXT }}</el-button>
            <slot name="edit-dialog-save" :update="async (m: M) => await update(m)">
              <el-button type="primary" @click="update()">
                Speichern
              </el-button>
            </slot>
          </template>
        </el-dialog>
      </slot>
    </slot>
  </div>
</template>


<style lang="scss">

.el-select .el-select__wrapper {
  width: 100%;
}

.options.el-button-group {

  // el-select-v2
  &:first-child .el-select-v2.button-grouped .el-select-v2__wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0 !important;
  }


  // el-select-v2
  &:last-child .el-select-v2.button-grouped .el-select-v2__wrapper {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .el-select-v2 .el-select-v2__wrapper {
    justify-content: left !important;

    .el-select-v2__selected-item.el-select-v2__input-wrapper {
      width: 100%;
    }
  }

  .el-select-v2__input-wrapper,
  .el-select-v2__placeholder {
    margin-inline-start: 7px !important;
  }

  .el-select-v2.button-grouped div[aria-describedby] .el-select-v2__placeholder {
    color: var(--el-text-color-placeholder);

  }

  // el-select
  & > .button-grouped {

    float: left;
    position: relative;

    &:first-child .el-input__wrapper {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right-width: 0 !important;
    }


    &:last-child .el-input__wrapper {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .el-button--small {
    padding: 5px 11px !important;
    height: var(--el-button-size) !important;
  }

}

.options.dialog-footer button:first-child {
  margin-right: 10px;
}

</style>