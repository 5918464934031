import { IModel, IRuntimeTypeInformation, Id, LocalMonth, Model } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"

export interface IAddisonMandantExport extends IModel {
	mandant: string
	exists: boolean
	zeitraumMax: string | null
	zeitraumMin: string | null
}

export class AddisonMandantExport extends Model<AddisonMandantExport> {
	public mandant!: Id<Mandant>
	public exists!: boolean
	public zeitraumMax!: LocalMonth | null
	public zeitraumMin!: LocalMonth | null

	static _types: Record<keyof IAddisonMandantExport, IRuntimeTypeInformation> = {
		id: { type: Id },
		mandant: { type: Id },
		exists: { type: Boolean },
		zeitraumMax: { type: LocalMonth, nullable: true },
		zeitraumMin: { type: LocalMonth, nullable: true },
	}

	static props: Record<keyof IAddisonMandantExport, string> = {
		id: "id",
		mandant: "mandant",
		exists: "exists",
		zeitraumMax: "zeitraumMax",
		zeitraumMin: "zeitraumMin",
	}

	constructor(_json: IAddisonMandantExport) {
		super(_json)
	}
}
