<template>
  <el-table
          ref="table"
          :cell-class-name="cellClassName"
          :data="tableData"
          :row-class-name="rowClassName"
          :show-header="showHeader"
          class="artikel-erstellung-table"
  >
    <el-table-column :label="KatalogPositionViewModel.label.text"
                     :property="KatalogPositionViewModel.properties.text" :width="420"
    >
      <template #default="{row}">
        <validateable-input
                ref="input"
                :error="error(row.validationIndex, KatalogPositionViewModel.properties.text)"
                :model-value="row.text || ''"
                :name="KatalogPositionViewModel.properties.text"
                :placeholder="row.artikelName"
                :size="FontSize.small"
                @validate="validate()"
                @update:modelValue="row.text=$event"
        ></validateable-input>
      </template>
    </el-table-column>
    <el-table-column :label="KatalogPositionViewModel.label.notiz"
                     :property="KatalogPositionViewModel.properties.notiz" :width="300"
    >
      <template #default="{row}">
        <validateable-input
                :error="error(row.validationIndex, KatalogPositionViewModel.properties.notiz)"
                :model-value="row.notiz || ''"
                :name="KatalogPositionViewModel.properties.notiz"
                :size="FontSize.small"
                @validate="validate()"
                @update:modelValue="row.notiz=$event"
        ></validateable-input>
      </template>
    </el-table-column>
    <el-table-column></el-table-column>
    <el-table-column :label="KatalogPositionViewModel.label.menge" align="center" fixed="right" width="150">
      <template #default="{row}">
        <validateable-input
                v-model.number="row.menge"
                :error="error(row.validationIndex, KatalogPositionViewModel.properties.menge)"
                :min="0"
                :name="KatalogPositionViewModel.properties.menge" :step="1" :size="FontSize.small" :type="InputType.InputNumber"
                @validate="validate"
        />
      </template>
    </el-table-column>
    <el-table-column v-if="preise"
                     :label="KatalogPositionViewModel.label.netto"
                     :prop="KatalogPositionViewModel.properties.netto"
                     align="right"
                     fixed="right"
                     width="100"
    >
      <template #default="{row}">
        <el-tooltip :disabled="!row.hasMandantPreis()" content="Mandantenpreis">
          {{ row.netto }}
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
            v-if="preise"
            :label="KatalogPositionViewModel.label.summe"
            :prop="KatalogPositionViewModel.properties.summe"
            :width="100"
            align="right"
            fixed="right"

    />
    <el-table-column v-if="deletable" :width="20" fixed="left">
      <template #default="{row}">
        <el-button v-if="!firstEmptyRow(row)" text @click.stop="clearOrDelete(row)">
          <icon-delete></icon-delete>
        </el-button>
      </template>
    </el-table-column>
    <el-table-column v-if="createable" :width="40" fixed="right">
      <template #default="{row}">
        <el-button
                v-if="firstRow(row)"
                data-cy="katalogartikel-add"
                text
                @click="add(row)"
        >
          <icon-add />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from "vue"
import {Icons} from "@lib/view/icons"
import {ElTable} from "element-plus"
import {BelegBuilderController} from "@intranet/view/rechnung/builder/BelegBuilderController"
import {KatalogPositionViewModel} from "@intranet/view/rechnung/builder/KatalogPositionViewModel"
import {ValidateableInput} from "@lib/view"
import {Validator} from "@lib/common/Validator"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {_props} from "@lib/common/_props"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {KatalogArtikelPosition} from "@generated/de/lohn24/model/beleg/builder/KatalogArtikelPosition"
import {UuidService} from "@lib/common/uuid/UuidService"
import {Pair} from "@lib/common/repository/RepositoryHasManyWithRelation"
import {FontSize} from "@lib/common/Enums"
import {InputType} from "@lib/view/validateable/ValidateableProps"

export default defineComponent({
    name: "KatalogArtikelPositionTable",
    computed: {
        InputType() {
            return InputType
        },
        FontSize() {
            return FontSize
        }
    },
    components: {
        ValidateableInput,
        IconAdd: Icons.IconAdd,
        IconDelete: Icons.IconDelete,
    },
    props: {
        controller: {
            type: Object as PropType<BelegBuilderController>,
            required: true,
        },
        data: {
            type: Array as PropType<Array<KatalogPositionViewModel>>,
        },
        preise: Boolean,
        summe: Boolean,
        showHeader: {
            type: Boolean,
            default: true,
        },
        deletable: Boolean,
        createable: Boolean,
    },
    setup(props) {

        const table = ref<InstanceType<typeof ElTable>>()

        const tableData = computed<KatalogPositionViewModel[]>(() => {
            return (props.data || props.controller.katalogArtikelPositionen.value)
                .map((it, index) => {
                    it.validationIndex = index
                    return it
                })
                .sortBy(it => UuidService.timeStamp((it.katalogArtikelPosition as KatalogArtikelPosition).artikel.value))
        })

        function cellClassName({column}: {
            row: { name: string, children: any[] },
            column: { property: string },
            rowIndex,
            columnIndex
        }) {
            const classNames: string[] = []
            if (column.property === KatalogPositionViewModel.properties.text)
                classNames.push("no-box-shadow")
            if (column.property === KatalogPositionViewModel.properties.notiz)
                classNames.push("no-box-shadow")
            if (column.property === KatalogPositionViewModel.properties.katalogArtikelPosition) {
                classNames.push("no-box-shadow")
                classNames.push("align-right")
            }
            return classNames.join(" ")
        }

        function rowClassName({row}: { row: KatalogPositionViewModel, rowIndex: number }) {
            const classNames: string[] = []
            if (row.bearbeitungszustand.deleted) {
                classNames.push("row-deleted")
            }
            if (isLastRowForArtikel(row)) {
                classNames.push("border-bottom")
            }
            return classNames.join(" ")
        }

        function clearOrDelete(row: KatalogPositionViewModel) {
            if (row.artikel === null) return

            props.controller.removeKatalogArtikel(row)
            if (!props.controller.katalogArtikelPositionen.value.find(it => {
                return it.artikel && it.artikel.id.value === row.artikel?.id.value
            })) {
                props.controller.addKatalogArtikel(
                    new Pair(row.artikelkatalogArtikel, row.artikel),
                    row.mandantArtikel,
                )
            }
        }

        function add(row: KatalogPositionViewModel) {
            row.artikel && props.controller.addKatalogArtikel(new Pair(row.artikelkatalogArtikel, row.artikel), row.mandantArtikel)
        }

        function firstRow(row: KatalogPositionViewModel): boolean {
            const rows = props.controller.katalogArtikelPositionen.value.filter(it => it.artikel === row.artikel)
            return rows[0] === row
        }

        function isFirstEmptyRow(row: KatalogPositionViewModel): boolean {
            const rows = props.controller.katalogArtikelPositionen.value.filter(it =>
                it.artikel?.id.value === row.artikel?.id?.value
                && it.menge === 0
                && it.textIsBlanc(),
            )
            return rows[0] === row
        }

        function isLastRowForArtikel(row: KatalogPositionViewModel): boolean {
            const rows = props.controller.katalogArtikelPositionen.value.filter(it => {
                return it.artikel?.id.value === row.artikel?.id?.value
            })
            return row === rows[rows.length - 1]
        }

        function error(index: number, name: string): string {
            return Validator.getErrorText(
                `${props.controller.validatorIdentifier}.${_props<BelegBuilder>().katalogArtikelPositionen}.${index}.${name}`,
            )
        }

        async function validate(): Promise<void> {
            try {
                await props.controller.validate()
            } catch (e) {
                if (e instanceof ValidationError) {
                    // nur Visuell
                } else {
                    throw e
                }
            }
        }

        return {
            table,
            tableData,
            add,
            error,
            validate,
            KatalogPositionViewModel,
            cellClassName,
            rowClassName,
            clearOrDelete,
            firstRow,
            firstEmptyRow: isFirstEmptyRow,
        }
    },
})
</script>

<style lang="scss">
.artikel-erstellung-table-grouped:first-of-type {
  .el-table__body-wrapper {
    display: none;
  }
}

.artikel-erstellung-table {
  .hidden .cell {
    visibility: hidden;
  }

  .no-box-shadow {
    .el-input__wrapper {
      box-shadow: none !important;
    }
  }

  //.align-right {
  //  input {
  //    text-align: right;
  //  }
  //}

  .row-deleted {
    opacity: 0.4;

    .el-table-fixed-column--right {
      opacity: 0.4;
    }
  }
}
</style>