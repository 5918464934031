import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface ISftpConfiguration {
	port: number
	destinationDirectory: string | null
	host: string
	password: string
	username: string
}

export class SftpConfiguration extends Serializable<SftpConfiguration> {
	public port!: number
	public destinationDirectory!: string | null
	public host!: string
	public password!: string
	public username!: string

	static _types: Record<keyof ISftpConfiguration, IRuntimeTypeInformation> = {
		port: { type: Number },
		destinationDirectory: { type: String, nullable: true },
		host: { type: String },
		password: { type: String },
		username: { type: String },
	}

	static props: Record<keyof ISftpConfiguration, string> = {
		port: "port",
		destinationDirectory: "destinationDirectory",
		host: "host",
		password: "password",
		username: "username",
	}

	constructor(_json: ISftpConfiguration) {
		super(_json)
	}
}
