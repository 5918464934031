import {Serializable} from "@lib/common/serializable/Serializable"
import {LocalDate} from "@lib/common/LocalDate"
import {DateTime} from "luxon"
import {PbxCallDirection} from "@generated/de/lohn24/model/pbx/PbxCallDirection"
import {DebitorStatus} from "@generated/de/lohn24/model/debitor/DebitorStatus"
import {DebitorSepa} from "@generated/de/lohn24/model/debitor/DebitorSepa"
import {BriefabsenderArt} from "@generated/de/lohn24/model/brief/BriefabsenderArt"
import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {OrganisationStatus} from "@generated/de/lohn24/model/organisation/OrganisationStatus"
import {AutomatischeRechnung} from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
// import {Abrechnungssystem} from "@generated/de/lohn24/model/Abrechnungssystem"
import {Beitragsnachweis} from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import {Lohnsteueranmeldeperiode} from "@generated/de/lohn24/model/mandant/Lohnsteueranmeldeperiode"
import {NewsContentType} from "@generated/de/lohn24/model/news/NewsContentType"
import {ArtikelEinheit} from "@generated/de/lohn24/model/artikel/ArtikelEinheit"
import {KontaktFunktion} from "@generated/de/lohn24/model/kontakt/KontaktFunktion"
import {Id} from "@lib/common/model/Id"
import {UuidService} from "@lib/common/uuid/UuidService"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

Serializable.addDefaultValue(Number, () => 0)
Serializable.addDefaultValue(Id, () => new Id(UuidService.v7()))
Serializable.addDefaultValue(LocalDate, () => LocalDate.now())
// Serializable.addDefaultValue(LocalMonth, () => LocalMonth.now())
Serializable.addDefaultValue(DateTime, () => DateTime.now())
Serializable.addDefaultValue(String, () => "")
Serializable.addDefaultValue(PbxCallDirection, () => PbxCallDirection.Inbound)
Serializable.addDefaultValue(DebitorStatus, () => DebitorStatus.AKTIV)
Serializable.addDefaultValue(DebitorSepa, () => DebitorSepa.BASISLASTSCHRIFT)
Serializable.addDefaultValue(BriefabsenderArt, () => BriefabsenderArt.UNTERNEHMEN)
Serializable.addDefaultValue(AnschriftArt, () => AnschriftArt.UNTERNEHMEN)
Serializable.addDefaultValue(OrganisationStatus, () => OrganisationStatus.AKTIV)
Serializable.addDefaultValue(AutomatischeRechnung, () => AutomatischeRechnung.NEIN)
Serializable.addDefaultValue(LohnabrechnungSystem, () => LohnabrechnungSystem.ADDISON)
Serializable.addDefaultValue(Beitragsnachweis, () => Beitragsnachweis.KEIN)
Serializable.addDefaultValue(Lohnsteueranmeldeperiode, () => Lohnsteueranmeldeperiode.JAHR)
Serializable.addDefaultValue(NewsContentType, () => NewsContentType.HTML)
Serializable.addDefaultValue(ArtikelEinheit, () => ArtikelEinheit.EURO)
Serializable.addDefaultValue(KontaktFunktion, () => KontaktFunktion.ANSPRECHPARTNER)