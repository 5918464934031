import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Model } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { AddisonExportPriority } from "@generated/de/lohn24/model/exportitem/AddisonExportPriority"
import { AddisonExportItemType } from "@generated/de/lohn24/model/exportitem/AddisonExportItemType"

export interface IAddisonExportItem extends IModel {
	mandant: string
	type: AddisonExportItemType
	priority: AddisonExportPriority
	created: LocalDateTime
	end: LocalDateTime | null
	start: LocalDateTime | null
	timeout: LocalDateTime | null
	zeitraum: string | null
	duration: number | null
	origin: string
	timeoutReason: string | null
}

export class AddisonExportItem extends Model<AddisonExportItem> {
	public mandant!: Id<Mandant>
	public type!: AddisonExportItemType
	public priority!: AddisonExportPriority
	public created!: LocalDateTime
	public end!: LocalDateTime | null
	public start!: LocalDateTime | null
	public timeout!: LocalDateTime | null
	public zeitraum!: LocalMonth | null
	public duration!: number | null
	public origin!: string
	public timeoutReason!: string | null

	static _types: Record<keyof IAddisonExportItem, IRuntimeTypeInformation> = {
		id: { type: Id },
		mandant: { type: Id },
		type: { type: AddisonExportItemType },
		priority: { type: AddisonExportPriority },
		created: { type: LocalDateTime },
		end: { type: LocalDateTime, nullable: true },
		start: { type: LocalDateTime, nullable: true },
		timeout: { type: LocalDateTime, nullable: true },
		zeitraum: { type: LocalMonth, nullable: true },
		duration: { type: Number, nullable: true },
		origin: { type: String },
		timeoutReason: { type: String, nullable: true },
	}

	static props: Record<keyof IAddisonExportItem, string> = {
		id: "id",
		mandant: "mandant",
		type: "type",
		priority: "priority",
		created: "created",
		end: "end",
		start: "start",
		timeout: "timeout",
		zeitraum: "zeitraum",
		duration: "duration",
		origin: "origin",
		timeoutReason: "timeoutReason",
	}

	constructor(_json: IAddisonExportItem) {
		super(_json)
	}
}
