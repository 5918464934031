import { IModel, IRuntimeTypeInformation, Id, LocalMonth, Model } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { LohnabrechnungStatus } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungStatus"

export interface IAddisonLohnabrechnungExport extends IModel {
	mandant: string
	abrechnungsstatus: Array<LohnabrechnungStatus>
	exportErrors: Array<string> | null
	version: number | null
	zeitraum: string
}

export class AddisonLohnabrechnungExport extends Model<AddisonLohnabrechnungExport> {
	public mandant!: Id<Mandant>
	public abrechnungsstatus!: Array<LohnabrechnungStatus>
	public exportErrors!: Array<string> | null
	public version!: number | null
	public zeitraum!: LocalMonth

	static _types: Record<keyof IAddisonLohnabrechnungExport, IRuntimeTypeInformation> = {
		id: { type: Id },
		mandant: { type: Id },
		abrechnungsstatus: { type: [LohnabrechnungStatus] },
		exportErrors: { type: [String], nullable: true },
		version: { type: Number, nullable: true },
		zeitraum: { type: LocalMonth },
	}

	static props: Record<keyof IAddisonLohnabrechnungExport, string> = {
		id: "id",
		mandant: "mandant",
		abrechnungsstatus: "abrechnungsstatus",
		exportErrors: "exportErrors",
		version: "version",
		zeitraum: "zeitraum",
	}

	constructor(_json: IAddisonLohnabrechnungExport) {
		super(_json)
	}
}
